import { Code, ContentCopy, Edit, ExpandMore, PlayArrow } from '@mui/icons-material';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   BoxProps,
   Stack,
   Typography,
   useTheme,
   withStyles,
} from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { CButton } from '../../common/components/CButton';
import { CPlayWindow } from '../../common/components/CPlayWindow';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { config } from '../../common/config/config';
import { lang, langM } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { wconfig } from '../config/wconfig';
import { WMapInfoData, WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { WAvatar } from './WAvatar';

interface WArticleProps extends BoxProps {
   mapId: string;
   // title: string;
   // desc: string;
   // userName: string;
   // userId: string;
   // userPhoto?: string;
   // editable?: boolean;
   map: WMapInfoData;
   preview: boolean;
}

export const WArticle = memo((props: WArticleProps) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   let theme = useTheme();
   let editable = !!(WRepository.user && props.map.owner === WRepository.user.uid);
   let cloneable = !!(WRepository.user && props.map.owner === WRepository.user.uid);
   let [preview, setPreview] = useState(props.preview);
   let [mode, setMode] = useState('play');
   useEffect(() => {
      setPreview(props.preview);
   }, [props.preview]);

   return (
      <Box
         width="100%"
         // className="zoomIn"
         p={1.5}
         sx={{
            // bgcolor: 'lightblue',
            borderRadius: 1,
            boxShadow: '0px 0px 30px 1px rgba(0, 0, 0, .2)',
         }}
      >
         {/* <Stack direction={'row'}> */}
         {/* <Stack justifyContent={'center'}></Stack> */}
         {/* <Box width={'100%'}> */}
         <Box pb={1}>
            <Stack
               p={1}
               pt={0}
               pb={0}
               direction={'row'}
               justifyContent="flex-end"
               alignItems={'center'}
               spacing={0.5}
            >
               <Typography flex={1} fontSize={'0.7em'} textAlign={'left'} color={'text.secondary'}>{`${
                  lang.common.카테고리
               } > ${langM.GetLocalizedText('common', props.map.category)}`}</Typography>
               <Typography m={0.2} color={'text.secondary'} textAlign={'right'} fontSize={'0.7em'}>
                  {props.map.updatedAt.toDate().toLocaleDateString()}
               </Typography>
            </Stack>
            <Accordion
               onChange={(event: any, expanded: boolean) => {
                  setPreview(expanded);
               }}
               expanded={preview}
               disableGutters
               // sx={{
               //    borderRadius: 1,
               //    // boxShadow: '0px 0px 30px 1px rgba(0, 0, 0, .2)',
               // }}
            >
               <AccordionSummary expandIcon={<ExpandMore />}>
                  {/* <Stack width={'100%'} direction={'column'} justifyContent="center"> */}
                  <Stack width={'100%'} direction={'row'} alignItems={'center'}>
                     {/* <Stack pl={1} pr={0.5} direction={'column'} justifyContent="center"> */}
                     <Typography
                        flex={1}
                        // marginRight={'auto'}// 나머지를 오른쪽으로 밀어버린다.
                        m={0.3}
                        noWrap
                        // whiteSpace={'nowrap'}
                        // overflow={'hidden'}
                        // textOverflow="ellipsis"
                        color={`${theme.palette.primary.main}`}
                        fontSize="1.2em"
                        fontWeight="bold"
                        textAlign="left"
                     >
                        {props.map.title}
                     </Typography>
                     {!props.map.sort && (
                        <Box
                           m={0.2}
                           p={0.5}
                           pt={0.1}
                           pb={0.1}
                           borderRadius={5}
                           bgcolor={blue[200]}
                           fontSize={'0.6em'}
                        >
                           {lang.common.내용없음}
                        </Box>
                     )}{' '}
                     {props.map.visibility === 'private' && (
                        <Box
                           m={0.2}
                           p={0.5}
                           pt={0.1}
                           pb={0.1}
                           borderRadius={5}
                           bgcolor={red[200]}
                           fontSize={'0.6em'}
                        >
                           {lang.common.비공개}
                        </Box>
                     )}
                     {props.map.visibility === 'friends' && (
                        <Box
                           m={0.2}
                           p={0.5}
                           pt={0.1}
                           pb={0.1}
                           borderRadius={5}
                           bgcolor={red[200]}
                           fontSize={'0.6em'}
                        >
                           {lang.common.친구만공개}
                        </Box>
                     )}
                  </Stack>
               </AccordionSummary>
               <AccordionDetails sx={{ p: 0.8, pt: 0 }}>
                  {/* pre + wrap */}
                  <Typography p={1} pt={0} textAlign={'left'} sx={{ whiteSpace: 'pre-wrap' }}>
                     {props.map.desc}
                  </Typography>
                  {preview && (
                     // <CPlayWindow sx={{ position: 'fixed', left:0, top:0, height: '100vh', width: '100vw', zIndex:1 }}>
                     <CPlayWindow sx={{ height: 500, width: '100%', zIndex: 1 }}>
                        <iframe
                           id={props.mapId}
                           title={props.map.title}
                           frameBorder={0}
                           width="100%"
                           height="100%"
                           style={{ borderRadius: 5 }}
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                           allowFullScreen
                           src={wconfig.env.dev ? 'http://localhost:7456/' : `${MyStringUtil.GetHostURL()}/worldmaker/loader.html?mode=${mode}&window=embed&room=${
                              props.mapId
                           }`}
                        ></iframe>
                        {/* <iframe
                           id={props.mapId}
                           title={props.map.title}
                           frameBorder={0}
                           width="100%"
                           height="100%"
                           style={{ borderRadius: 5 }}
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                           allowFullScreen
                           src={`${MyStringUtil.GetHostURL()}/embed/${props.mapId}/${mode}`}
                        ></iframe> */}
                     </CPlayWindow>
                  )}
                  <Stack pt={0.5} direction={'row'} spacing={1} justifyContent={'end'}>
                     <CButton
                        sx={{ fontSize: '0.9em' }}
                        color={'secondary'}
                        startIcon={<Code />}
                        onClick={async () => {
                           let code =
                              `<iframe title="${props.map.title}"` +
                              ` frameBorder=0 width="100%" height="400" style="max-width:800px;border-radius:5px"` +
                              ` allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"` +
                              ` allowfullscreen` +
                              ` src="${MyStringUtil.GetHostURL()}/embed/${props.mapId}">` +
                              `</iframe>`;
                           navigator.clipboard.writeText(code);
                           MsgBoxM.Open(code, lang.common.복사완료);
                        }}
                     >
                        <Typography noWrap>{lang.common.소스코드복사}</Typography>
                     </CButton>
                     {cloneable && (
                        <CButton
                           sx={{ fontSize: '0.9em' }}
                           startIcon={<ContentCopy />}
                           color={'secondary'}
                           onClick={async () => {
                              WSystem.OpenRoomCreateWindow(props.map, props.mapId);
                           }}
                        >
                           <Typography noWrap>{lang.common.복제}</Typography>
                        </CButton>
                     )}
                     {editable && mode === 'play' && (
                        <CButton
                           startIcon={<Edit />}
                           sx={{ fontSize: '0.9em' }}
                           onClick={async () => {
                              WSystem.OpenRoomInfoWindow(props.mapId, props.map, () => {
                                 setMode('edit');
                              });
                           }}
                        >
                           <Typography noWrap>{lang.common.편집.toUpperCase()}</Typography>
                        </CButton>
                     )}
                     {mode === 'edit' && (
                        <CButton
                           startIcon={<PlayArrow />}
                           sx={{ fontSize: '0.9em' }}
                           onClick={async () => {
                              let run = false;
                              if (WRepository.user) {
                                 run = true;
                              } else {
                                 run = true;
                                 if (config.USE_LOGIN) {
                                    let ret = await MsgBoxM.Open(
                                       lang.common.로그인하지않고계속진행하시겠습니까,
                                       lang.common.로그인,
                                       lang.common.계속,
                                    );
                                    if (ret.btn === lang.common.로그인) {
                                       // 로그인
                                       await MyFirebaseM.Login();
                                       run = false;
                                    } else if (ret.btn === 'Cancel') {
                                       //비로그인 상태로 진행
                                       run = true;
                                    } else {
                                       run = false;
                                    }
                                 }
                              }
                              if (run) {
                                 // WSystem.OpenMap(props.mapId);
                                 setMode('play');
                              }
                           }}
                        >
                           <Typography noWrap>{lang.common.플레이.toUpperCase()}</Typography>
                        </CButton>
                     )}
                  </Stack>
               </AccordionDetails>
            </Accordion>
         </Box>
         <Stack direction={'row'} justifyContent="end" pt={0} pl={1} pr={1} spacing={1} alignItems={'center'}>
            <WAvatar
               photo={props.map.ownerPhoto}
               onClick={() => {
                  props.map.owner && WSystem.OpenUserInfoWindowById(props.map.owner);
               }}
            >
               <>
                  <Typography noWrap fontWeight="bold">
                     {props.map.ownerName}
                  </Typography>
                  {/* <Box
                           display="inline-block"
                           m={0.5}
                           p={0.5}
                           borderRadius={5}
                           bgcolor={blue[300]}
                           fontSize={'0.5em'}
                        >
                           {props.map.ownerName}
                        </Box> */}
               </>
            </WAvatar>
            {/* {props.editable && (
                     <CButton
                        color={'secondary'}
                        onClick={async () => {
                           let ret = await MsgBoxM.Open(
                              lang.common.정말삭제하시겠습니까,
                              lang.common.ok,
                              lang.common.cancel,
                           );
                           if (ret.btn === lang.common.ok) {
                              await WRepository.DeleteMap(props.uid);
                              MMSystem.ForceUpdate();
                           }
                        }}
                     >
                        <Delete sx={{ fontSize: '1.9em', color: red[400] }} />
                     </CButton>
                  )} */}
         </Stack>
         {/* </Box> */}
         {/* </Stack> */}
      </Box>
   );
});
