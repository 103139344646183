import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { config } from '../../config/config';
import { MyStringUtil } from '../../base/MyStringUtil';
import { MyDebug } from '../../util/MyDebug';

// redux counter 상태 구독
// let w = watch(store.getState, 'counter');
// store.subscribe(
//   w((newVal, oldVal, objectPath) => {
//     console.log(`${objectPath} changed from ${JSON.stringify(oldVal)} to ${JSON.stringify(newVal)}`);
//   }),
// );

/**
 * 토스트 알람 매니저
 * @author elee
 * @date 2021-03-15,
 */
export class ToastM {
   private static stackCnt = 0;
   static enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
   static closeSnackbar: (key?: SnackbarKey) => void;

   /**
    * 스택으로 여러개가 쌓이는 토스트 알람
    * 동일한 키나 내용은 중복 표시 되지 않는다.
    * @param msg 보여줄 내용
    * @param variant 아이콘 모양
    * @param key 같은 토스트가 두번 뜨지 않게 해주는 아이디. close에도 사용됨
    * @param block 토스트가 떠 있는 동안 마우스 클릭을 막을 수 있음
    * @param persist true인 경우 자동으로 닫히지 않음
    * @param preventDuplicate 동일 토스트 중복 방지 여부
    */
   static Open(
      msg: any,
      variant: 'error' | 'success' | 'info' | 'warning' = 'info',
      key?: string,
      block: boolean = true,
      persist: boolean = false,
      preventDuplicate: boolean = false,
   ) {
      msg = MyStringUtil.StringVal(msg);
      msg = MyStringUtil.Capitalize(msg);

      if (!this.enqueueSnackbar || !this.closeSnackbar) {
         //토스트 준비 안됨
         console.log(msg);
         return;
      }

      if (key === undefined && preventDuplicate) key = msg;

      if (MyDebug.IsDebug())
         //로그
         console.log(`${variant} : ${msg}`);
      // store.dispatch(NotifyReducer.msg(msg, type));
      //닫고
      // ToastManager.closeSnackbar(handle); //즉시 닫히지 않는다
      //열기
      if (block) ++this.stackCnt;
      // if (ToastManager.stackCnt > 0) ToastManager.SetTouchInputEnable(false); //입력 블럭
      this.enqueueSnackbar(msg, {
         variant: variant,
         key: key, //중복 내용은 무시되게
         persist: persist,
         preventDuplicate: preventDuplicate, //중복 내용은 무시되게
         autoHideDuration: config.NOTIFICATION_DELAY,
         // transitionDuration: { exit: 0 },
         onClose: (e) => {
            if (e === null) {
               // ! 널 체크 해야지 한번만 호출된다. 왜??
               if (block) --this.stackCnt;
               if (this.stackCnt < 0) this.stackCnt = 0;
               // if (ToastManager.stackCnt === 0) ToastManager.SetTouchInputEnable(true); //입력 블럭 해제
            }
         },
      });
   }

   /**
    * 디버깅 모드일때만 실행되는 알람
    * 중복된 내용의 알람도 뜸
    */
   static OpenDebug(msg: string, variant: 'error' | 'success' | 'info' | 'warning' = 'info') {
      if (MyDebug.IsDebug()) this.Open(msg, variant);
   }

   /** 토스트 닫기 */
   static Close(handle: string) {
      this.closeSnackbar(handle);
   }
}
