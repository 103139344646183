import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import React from 'react';
import { RootStateType } from '../../common/reducer/RootReducer';
import { WMapList } from '../components/WMapList';
import { WRepository } from '../manager/WRepository';
import { lang, langM } from '../../common/config/lang';
import { CButton } from '../../common/components/CButton';
import { Add } from '@mui/icons-material';
import { WSystem } from '../manager/WSystem';
import { MyStringUtil } from '../../common/base/MyStringUtil';

export const WPageRoomList = memo((props: { category: string }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   React.useEffect(() => {
      //이미지 URL이 바뀌면 이미지 데이터도 초기화 한다.
      WRepository.LoadMapList(props.category);
   }, [props.category]);

   return (
      <Box p={1} {...props}>
         {/* {props.category === 'my' && <Box pb={2}>
            <CButton
               startIcon={<Add />}
               color={'secondary'}
               onClick={() => {
                  WSystem.OpenRoomCreateWindow();
               }}
            >
               {lang.w.새월드만들기}
            </CButton>
         </Box>} */}
         {/* 제목 */}
         {/* {WRepository.user && (
            <WSubTitle>
               <Stack direction={'row'} justifyContent={'center'} spacing={1} alignItems={'center'}>
                  <img width={30} height={30} src={'./globe.png'} alt="" />
                  <Box>{lang.w.추천월드목록}</Box>
                  <CIconButton
                     onClick={async () => {
                        WRepository.LoadMapList();
                        MySystem.ForceUpdate();
                     }}
                  >
                     {!MyTaskM.Empty() ? <CircularProgress size={20} /> : <Refresh />}
                  </CIconButton>
               </Stack>
            </WSubTitle>
         )} */}
         <Box pb={2}>
            <CButton
               startIcon={<Add />}
               color={'secondary'}
               onClick={() => {
                  WSystem.OpenRoomCreateWindow(undefined, undefined, props.category);
               }}
            >
               {props.category === 'my' || props.category === 'all' || props.category === 'etc'
                  ? lang.w.새월드만들기
                  : MyStringUtil.Format(lang.w.새XXX월드만들기, langM.GetLocalizedText('common', props.category))}
            </CButton>
         </Box>
         <WMapList mapList={WRepository.mapList} />
      </Box>
   );
});
