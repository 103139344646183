import { MyDebug } from "../util/MyDebug";

let dict: any = {};

/**
 * 싱글톤 생성 함수
 * @author elee
 * Date: 2022-03-22
 */
export function Singleton<T>(type: new () => T, className: string) {
   // let className = type.name;
   // MyDebug.Log('Singleton: ' + className);
   if (dict[className]) return <T>dict[className]; //싱글톤이 이미 존재

   // 저장
   dict[className] = new type();
   MyDebug.Log('Singleton: ' + className + ' 싱글톤 생성');

   return dict[className] as T;
}

export function SingletonClear() {
   dict = {};
}
