import { MyStringUtil } from '../base/MyStringUtil';
import { MyUtil } from '../base/MyUtil';
import { MsgBoxM } from '../components/msgbox/MsgBoxM';
import { SMKeyValueTable } from '../components/SMKeyValueTable';
import { ToastM } from '../components/toast/ToastM';
import { lang, langM } from '../config/lang';
import { MyDebug } from '../util/MyDebug';
import { httpCode } from './httpCodes';
import { IAccountManager } from './IAccountManager';

/**
 * API base class
 * @author elee
 * @date 2021-06-16,
 */
export class APIBase {
   private static account?: IAccountManager;
   private static baseURL?: string;
   // private static conn?: AxiosInstance;
   private static reqHeader = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
   };

   private static async requestAPI(
      path: string,
      params: any,
      taskName?: string,
      json: boolean = true,
   ): Promise<Response | undefined> {
      const body = params;
      const header = { ...this.reqHeader };
      const res = await this.callAPI('POST', path, body, header, taskName, json);
      if (res) {
         const message = this.getMessage(res);
         if (res.status !== httpCode.OK) {
            //!OK가 아니면 전용 메시지 보여줌
            if (message) MsgBoxM.Open(message, lang.common.ok);
         }
         if (MyDebug.IsDebug()) {
            //!디버그 모드이면 전체 응답 보여줌
            if (MyDebug.IsDebug())
               MsgBoxM.Open(<SMKeyValueTable data={(res as any).data}></SMKeyValueTable>);
         }
         //!HTTP 응답 메시지 보여줌
         let httpStatus = '=> ' + path + ' ' + res.status + ' ' + res.statusText;
         if (message) httpStatus += ' : ' + message;
         MyDebug.Debug(httpStatus);
      }
      return res;
   }

   static async Request(path: string, params: any = {}, json: boolean = true) {
      if (path) params.func = path;
      const autoOK = !MyDebug.IsDebug();
      let ret = await MsgBoxM.Open(
         <SMKeyValueTable data={params} />,
         lang.common.보내기,
         undefined,
         undefined,
         false,
         autoOK,
      );
      if (ret.btn === lang.common.보내기) {
         return await this.requestAPI(
            path,
            params,
            MyStringUtil.Capitalize(path.replaceAll('/', ' ')),
            json,
         );
      }
   }

   /** 초기화
    * *todo call
    */
   static Init(account: IAccountManager, baseURL: string) {
      this.account = account;
      this.baseURL = baseURL;
   }

   static GetBaseURL() {
      return this.baseURL;
   }

   /** HTTP call */
   private static async callAPI(
      method: 'GET' | 'POST' | 'PUT' | 'DELETE',
      path: string,
      param: any,
      header: any,
      taskName?: string,
      json?: boolean,
   ): Promise<Response | undefined> {
      // MyDebug.Assert(this.conn, 'axios is not created!');

      // 데이터 구성
      let body: any = undefined;
      if (method === 'GET') {
         // Get 데이터 구성
         body = param;
      } else {
         if (json) {
            //JSON
            body = param;
         } else {
            // Post 폼데이터 구성
            const formd: FormData = (body = new FormData());
            for (let key in param) {
               if (Array.isArray(param[key])) {
                  //배열일 경우 하나씩 추가
                  const arrayParam: [] = param[key];
                  arrayParam.forEach((item) => formd.append(key, item));
               } else {
                  //단일값일 경우
                  body.append(key, param[key]);
               }
            }
         }
      }

      //태스크 이름 정하기
      if (!taskName) {
         taskName = path;
         let cnt = 0;
         for (let key in param) {
            if (param[key].length < 20) {
               taskName += ` ${param[key]}`;
               ++cnt;
            }
            if (cnt >= 3)
               //파라미터 최대 2개 까지 보여주기
               break;
         }
      }

      let response: Response | undefined = undefined;
      await MyDebug.Task(taskName, async () => {
         //인증키 헤더에 추가
         if (this.account?.IsLogin()) {
            this.account?.AddTokenHeader(header);
         }

         // if (this.conn) {
         //서버에 요청
         try {
            response = await fetch(MyStringUtil.AppendToken(this.baseURL ?? '', path, '/'), {
               body: JSON.stringify(body),
               headers: header,
               method: method,
            });
            if (response.body) (response as any).data = await response.json();
         } catch (e: any) {
            MyDebug.Error(e);
            ToastM.Open(lang.error.connection_failed, 'error');
            return;
         }

         if (response && response.status) {
            //api-key 만료됨
            // if (response.status === httpCode.Internal_Server_Error && response.data.detail === 'Signature has expired.')
            //api-key 만료됨
            if (response.status === httpCode.Unauthorized) {
               await this.account?.Logout();
               await MyUtil.Wait(3000);
            }
         }
      });
      //리턴
      return response;
   }

   /** 로컬라이징 된 에러 메세지를 가져온다. */
   private static getMessage(res: Response) {
      if (res) {
         let text = (res as any)?.data?.message;
         if (!text) text = res.statusText;
         return langM.GetLocalizedText('error', text);
      }
   }
}
