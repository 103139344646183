import { reduxStore } from './reduxStore';
// import { langM } from '../config/lang';

/**
 * 테스트용 리듀서
 * @author elee
 * @date 2021-03-29,
 */
const SET_CATEGORY = 'SET_CATEGORY';
const LOGS = 'STATUS_BAR_LOG';
const LOGS_CLEAR = 'STATUS_BAR_LOG_CLEAR';
const LOGS_PATCH = 'STATUS_BAR_PATCH';
const FORCE_UPDATE = 'FORCE_UPDATE';
const SET_TASK_COUNT = 'SET_TASK_COUNT';
const SIDE_MENU_OPEN = 'SIDE_MENU_OPEN';

export namespace UIAction {
   /** 프로젝트 이름 설정 */
   // export function setProjectName(name: string) {
   //   reduxStore.dispatch({ type: SET_PROJECT_NAME, payload: name });
   // }

   /** 메인 메뉴 슬라이드 열기 닫기 */
   export function SetSideMenuOpen(open: boolean) {
      reduxStore.dispatch({ type: SIDE_MENU_OPEN, payload: open });
   }

   /** 프로퍼티창 열기 닫기 */
   //   export function setPropertyWindowOpen(open: boolean) {
   //     store.dispatch({ type: INFO_WINDOW_OPEN, payload: open });
   //   }

   /** 로그 추가 */
   export function AddLog(pram: string) {
      reduxStore.dispatch({ type: LOGS, payload: pram });
   }

   /** 로그 추가 */
   export function ClearLog() {
      reduxStore.dispatch({ type: LOGS_CLEAR, payload: '' });
   }   

   /** 로그 수정 */
   export function PatchLog(pram: string) {
      reduxStore.dispatch({ type: LOGS_PATCH, payload: pram });
   }

   /** 상태 수정 */
   // export function setStatusBarStatus(pram: string) {
   //   reduxStore.dispatch({ type: STATUS_BAR_STATUS, payload: pram });
   // }

   /** 강제 업데이트 */
   export function ForceUpdate() {
      reduxStore.dispatch({ type: FORCE_UPDATE, payload: '' });
   }

   /** 태스크 */
   export function SetTaskCount(pram: number) {
      reduxStore.dispatch({ type: SET_TASK_COUNT, payload: pram });
   }

   /** 태스크 */
   export function SetCategory(pram: string) {
      reduxStore.dispatch({ type: SET_CATEGORY, payload: pram });
   }

   /** 언어 변경 */
   // export function setLanguage(pram: string) {
   //   reduxStore.dispatch({ type: SET_LANUGAGE, payload: pram });
   // }

   /** 페이지 변경 */
   // export function setPage(pram: any) {
   //   reduxStore.dispatch({ type: SET_PAGE, payload: pram });
   // }
}

/** 스토어 구조 */
interface IStore {
   sideMenuOpen: boolean;
   // propertyWindowOpen: boolean;
   // forceUpdateDiagram: boolean;
   // forceUpdateSmartGuide: boolean;
   forceUpdate: boolean;
   // pageComponent: any;
   statusBarLogs: string[]; //로그
   statusBarStatus: string; //우하단 정보
   // projectName: string;
   taskCount: number;
   category: string;
   // language: string;
}

// !초기 스토어 상태
const initialState: IStore = {
   sideMenuOpen: false,
   // propertyWindowOpen: false,
   // forceUpdateDiagram: false,
   // forceUpdateSmartGuide: false,
   // pageComponent: undefined,
   forceUpdate: false,
   statusBarLogs: [],
   statusBarStatus: '',
   // projectName: '',
   taskCount: 0,
   category: '',
   // language: langM.GetCurrentLanguage(),
};

/** 리듀서 */
export function UIReducer(state: IStore = initialState, action: { type: any; payload: any }): IStore {
   switch (action.type) {
      case SIDE_MENU_OPEN:
         return {
            ...state,
            sideMenuOpen: action.payload,
         };
      // case INFO_WINDOW_OPEN:
      //   return {
      //     ...state,
      //     propertyWindowOpen: action.payload,
      //   };
      case SET_CATEGORY:
         return {
            ...state,
            category: action.payload, //마지막에 로그 추가
         };
      case LOGS:
         return {
            ...state,
            statusBarLogs: [...state.statusBarLogs, action.payload], //마지막에 로그 추가
         };
      case LOGS_CLEAR:
         return {
            ...state,
            statusBarLogs: [],
         };         
      case LOGS_PATCH: {
         // '1: xxx' 에서 1 을 추출해서 1번 로그의 내용을 xxx로 교체한다
         const log = String(action.payload);
         const idx = log.indexOf(':');
         if (idx > 0) {
            const key = log.substring(0, idx);
            const list = [...state.statusBarLogs]; //리스트 복제본 생성
            //뒤에서 부터 찾아서 교체한다.
            for (let i = list.length - 1; i >= 0; --i) {
               if (list[i].startsWith(key)) {
                  list[i] = log;
                  break;
               }
            }
            return {
               ...state,
               statusBarLogs: list,
            };
         }
         break;
         // return {
         //   ...state,
         //   statusBarLogs: [...state.statusBarLogs, action.payload], //마지막에 로그 추가
         // };
      }
      // case STATUS_BAR_STATUS:
      //   return {
      //     ...state,
      //     statusBarStatus: action.payload,
      //   };
      // case FORCE_UPDATE_DIAGRAM:
      //   return {
      //     ...state,
      //     forceUpdateDiagram: !state.forceUpdateDiagram,
      //   };
      case FORCE_UPDATE:
         return {
            ...state,
            forceUpdate: !state.forceUpdate,
         };
      // case FORCE_UPDATE_GUIDE:
      //   return {
      //     ...state,
      //     forceUpdateSmartGuide: !state.forceUpdateSmartGuide,
      //   };
      // case SET_PROJECT_NAME:
      //   return {
      //     ...state,
      //     projectName: action.payload,
      //   };
      case SET_TASK_COUNT:
         return {
            ...state,
            taskCount: action.payload,
         };
      // case SET_LANUGAGE:
      //   return {
      //     ...state,
      //     language: action.payload,
      //   };
      // case SET_PAGE:
      //   return {
      //     ...state,
      //     pageComponent: action.payload,
      //   };
   }
   return state;
}
