import { assert } from 'console';
import { MyUtil } from '../../base/MyUtil';
import { ActionModal } from './ModalReducer';
import { MsgBoxReturn } from './MsgBoxM';
import { IMsgBox, IMsgBoxInput } from './MsgBoxReducer';

/**
 * 메시지 박스 매니저
 * @author elee
 * @date 2021-03-15,
 */
export class ModalM {
   static list: IMsgBox[] = [];
   static lastData: IMsgBox | undefined = undefined;

   /** 메시지: 비동기 식 */
   static async Open(
      label: any,
      btnOK?: string,
      btnCancel?: string,
      input?: IMsgBoxInput[],
      block?: boolean,
      autoOK?: boolean,
      fullScreen?: boolean,
   ): Promise<MsgBoxReturn> {
      if (autoOK) return { btn: btnOK || 'OK' } as MsgBoxReturn;

      //창 닫기
      let ret: any = undefined;

      //메시지 박스
      this.MsgBoxSync(
         label,
         btnOK,
         btnCancel,
         (r) => {
            ret = r;
         },
         input,
         block,
         fullScreen
      );

      //클릭 콜백 대기
      await MyUtil.WaitUntil(() => !!ret);

      return ret ?? ret;
   }

   /** 메시지 박스 열기 */
   private static MsgBoxSync(
      label?: any,
      btnOK?: string,
      btnCancel?: string,
      cb?: (ret: MsgBoxReturn) => void,
      input?: IMsgBoxInput[],
      block?: boolean,
      fullScreen?: boolean,
   ) {
      //새 데이터 푸시
      this.list.push({
         children: label,
         btnOK: btnOK,
         btnCancel: btnCancel,
         cb: cb,
         isOpen: true,
         input: input,
         block: block,
         fullScreen: fullScreen
      });

      // 데이터 적용
      this.Deque();
   }

   /** 열려 있는가? */
   static IsOpen(): boolean {
      return !!this.lastData;
   }

   /** 큐 적용 */
   private static Deque() {
      // 열려 있는 데이터가 있으면, 닫을때 다시 적용한다.
      if (!this.lastData && this.list.length > 0) {
         this.lastData = this.list.shift(); // 데이터리스트 가져와서
         if (this.lastData)
            ActionModal.setMsgBox(this.lastData); //보여줌
      }
   }

   /** 닫기 */
   static Close(btn: string = 'None') {
      // 콜백 호출
      if (this.lastData) {
         if (this.lastData.cb) {
            this.lastData.cb({ btn: btn });
         }
         this.lastData = undefined;

         // 클로즈 리덕스 호출
         ActionModal.setMsgBox({ isOpen: false });

         // 다음 데이터 적용
         this.Deque();
      }
   }
}
