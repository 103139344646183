import { MetaMaskProvider } from 'metamask-react';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Box, CssBaseline, Stack, ThemeProvider } from '@mui/material';

import { useState } from 'react';
import LOGO from '../../assets/img/plusdata_logo3.svg';
import { APIBase } from '../../common/api/APIBase';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { MyUtil } from '../../common/base/MyUtil';
import { CConsole } from '../../common/components/CConsole';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBox } from '../../common/components/msgbox/MsgBox';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { CNotificationStack } from '../../common/components/toast/CNotificationStack';
import { config } from '../../common/config/config';
import { lang } from '../../common/config/lang';
import { MyTaskM } from '../../common/manager/MyTaskM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MyDebug } from '../../common/util/MyDebug';
import { MySystem } from '../../common/util/MySystem';
import { WAppBar } from '../../w/components/WAppBar';
import { WMenuButton } from '../../w/components/WMenuButton';
import { wconfig } from '../../w/config/wconfig';
import { WAccount } from '../../w/manager/WAccount';
import { WRepository } from '../../w/manager/WRepository';
import { WSystem } from '../../w/manager/WSystem';
import { p_theme } from '../theme/p_theme';
import './PMain.css';
import { WPageBody } from './PPageBody';

function HandleResize() {
   MySystem.ForceUpdate();
}

function HandleKeydown(e: any) {
   //! 단축키 처리
   if (MySystem.IsDevelopBuild()) {
      if (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) {
         if (!e.repeat) {
            if (config.DEBUG && e.key === 'i') {
               //ctrl+i
               //변수 목록
               e.preventDefault();
               WSystem.ShowAllVariables();
            } else if (e.key === 'd') {
               //ctrl+d
               //디버깅 온오프
               e.preventDefault();
               config.DEBUG = !config.DEBUG;
               MySystem.ForceUpdate();
            }
         }
      }
   }
}

export const PMain = memo(() => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   const [category, setCategory] = useState('medical');

   React.useEffect(() => {
      init();
      return () => {
         window.removeEventListener('resize', HandleResize);
         document.removeEventListener('keydown', HandleKeydown);
      };
   }, []);

   const WMenu = memo((props: { children: any; category: string; icon?: any }) => {
      // let theme = useTheme();
      return (
         <WMenuButton
            selected={category === props.category}
            icon={props.icon}
            onClick={() => {
               // if (category === props.category) WRepository.LoadMapList(category);
               // else
               setCategory(props.category);
            }}
         >
            {props.children}
         </WMenuButton>
      );
   });

   let a = MyUtil.Undefined(
      ((ModalM.IsOpen() || MsgBoxM.IsOpen()) && 'blur') || (!MyTaskM.Empty() && 'blur_loading'),
   );
   return (
      <MetaMaskProvider>
         <ThemeProvider theme={p_theme}>
            <CssBaseline />
            <MsgBox
               data={useSelector((state: RootStateType) => state.modal)}
               onClose={() => ModalM.Close()}
            />
            <MsgBox data={useSelector((state: RootStateType) => state.msg)} onClose={() => MsgBoxM.Close()} />
            <CNotificationStack />
            {/* 루트 */}
            {/* 100vh 가 모바일에서 주소창 높이를 반영하지 않으므로 window.innerHeight를 사용한다 */}
            <Stack direction={'column'} height={window.innerHeight}>
               {/* 컨텐트 */}
               <Stack
                  className={a}
                  direction={'row'}
                  sx={{
                     flex: 1,
                     overflow: 'hidden',
                     overflowY: 'auto',
                     justifyContent: 'center',
                     textAlign: 'center',                     
                     // background: 'linear-gradient(to bottom, #aaaaaa 0px, #fff 80px)',
                  }}
               >
                  {/* 페이지 */}
                  <Box
                     sx={{
                        // position: 'relative',
                        height: '100%',
                        width: '100vw',
                        maxWidth: wconfig.ui.페이지최고넓이,
                        flex: 1,
                        p: 0,
                     }}
                  >
                     {/* 상단 메뉴 */}
                     <WAppBar
                        onLogout={() => {
                           category === 'my' && setCategory('all');
                        }}
                     />
                     {/* 제목 */}
                     <img
                        src={LOGO}
                        alt={LOGO}
                        width={300}
                        style={{ paddingLeft: 15, paddingRight: 15, maxWidth: '100%' }}
                     />
                     <Box p={1} />
                     {/* <WTitle>{lang.p.제목.toUpperCase()}</WTitle> */}
                     {/* <WSubTitle>{lang.p.개요}</WSubTitle> */}
                     {/* 내 방 목록 */}
                     <Box p={1}>
                        <WMenu category="medical">{lang.common.medical}</WMenu>
                        <WMenu category="gallery">{lang.common.gallery}</WMenu>
                        <Box display={'inline-block'} pl={0.6} pr={0.6}>
                           |
                        </Box>
                        <WMenu category="about">{lang.common.회사소개}</WMenu>
                     </Box>
                     <WPageBody category={category} />
                     {/* 어바웃 */}
                     {/* <Box p={1} /> */}
                     {/* <WPageAbout /> */}
                     <Box sx={{ textAlign: 'center' }}>
                        {/* <Box>
                           <img
                              style={{ position: 'relative', top: 1, margin: 5 }}
                              height={30}
                              src={'./logo2_256x128.png'}
                              alt=""
                           />
                        </Box> */}
                        <Box p={1} fontSize={'0.8em'}>
                           {lang.p.주소}
                        </Box>
                     </Box>
                  </Box>
                  {/* 메뉴 */}
                  {/* {RenderReactiveMenu()} */}
               </Stack>
               {/* 푸터 */}
               <CConsole showTasks useConsole />
            </Stack>
            {/* </Box> */}
         </ThemeProvider>
      </MetaMaskProvider>
   );
});

async function init() {
   // 초기화
   MyDebug.LogClear();
   MyDebug.Log(`${config.TITLE}`);   
   MyDebug.Log(MyStringUtil.FormatDateTime(new Date()));
   MyDebug.Log('User Agent: ' + navigator.userAgent);

   // 제목 변경
   document.title = lang.p.제목;
   // description 변경
   document.querySelector('meta[name="description"]')?.setAttribute('content', lang.p.어바웃01);

   // API 초기화
   APIBase.Init(WAccount.s, config.URL_API_SERVER);

   // 키 입력
   document.addEventListener('keydown', HandleKeydown);
   // 리사이즈
   window.addEventListener('resize', HandleResize);

   // 파이어베이스 초기화
   // const firebaseConfig = {
   //    apiKey: "AIzaSyDqON-nl0SRdE2oUWWiG-KDlUDc7cJWD8s",
   //    authDomain: "plusdata-506c0.firebaseapp.com",
   //    projectId: "plusdata-506c0",
   //    storageBucket: "plusdata-506c0.appspot.com",
   //    messagingSenderId: "609795996691",
   //    appId: "1:609795996691:web:278803a16ddc2d1a18d3e6",
   //    measurementId: "G-W0G20JLWL2"
   // };   
   // await MyFirebaseM.Init(firebaseConfig);

   // 게임 초기화
   await WRepository.Init();

   // 맵 리스트 가져오기
   // WRepository.LoadMyMapList();
   // 맵 리스트 가져오기
   // WRepository.LoadHotMapList();

   // 화면 갱신
   // await MyUtil.WaitUntil(() => MyTaskM.Empty());
   // MySystem.ForceUpdate();
}
