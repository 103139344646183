import { MetaMaskProvider } from 'metamask-react';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Box, CssBaseline, Stack, ThemeProvider } from '@mui/material';

import { FirebaseOptions } from 'firebase/app';
import { APIBase } from '../../common/api/APIBase';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { CConsole } from '../../common/components/CConsole';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBox } from '../../common/components/msgbox/MsgBox';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { CNotificationStack } from '../../common/components/toast/CNotificationStack';
import { config } from '../../common/config/config';
import { lang, langM } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { MyTaskM } from '../../common/manager/MyTaskM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { UIAction } from '../../common/reducer/UIReducer';
import { MyDebug } from '../../common/util/MyDebug';
import { MySystem } from '../../common/util/MySystem';
import { WAppBar } from '../components/WAppBar';
import { WMenuButton } from '../components/WMenuButton';
import { WSubTitle } from '../components/WTitle';
import { wconfig } from '../config/wconfig';
import { WAccount } from '../manager/WAccount';
import { WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { w_theme } from '../theme/w_theme';
import './WMain.css';
import { WPageAbout } from './WPageAbout';
import { WPageRoomList } from './WPageRoomList';

export const WMain2 = () => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   let category = useSelector((state: RootStateType) => state.ui.category) || 'all';

   const WMenu = memo((props: { children: any; category: string; icon?: any }) => {
      // let theme = useTheme();
      return (
         <WMenuButton
            selected={category === props.category}
            icon={props.icon}
            onClick={() => {
               if (category === props.category) WRepository.LoadMapList(category);
               else UIAction.SetCategory(props.category);
            }}
         >
            {props.children}
         </WMenuButton>
      );
   });

   let isLoading = !MyTaskM.Empty();
   return (
      <Stack direction={'column'} height={window.innerHeight}>
         {/* 컨텐트 */}
         <Stack
            className={ModalM.IsOpen() || MsgBoxM.IsOpen() ? 'blur' : undefined}
            direction={'row'}
            style={{
               flex: 1,
               overflow: 'hidden',
               overflowY: 'auto',
               justifyContent: 'center',
               textAlign: 'center',
               background: isLoading
                  ? 'linear-gradient(to bottom, #cccccc 0px ,#fff 200px)'
                  : 'linear-gradient(to bottom, #bbddff 0px, #fff 200px)',
            }}
         >
            {/* 페이지 */}
            <Box
               sx={{
                  // position: 'relative',
                  height: '100vh',
                  width: '100vw',
                  maxWidth: wconfig.ui.페이지최고넓이,
                  flex: 1,
                  p: 0,
               }}
            >
               {/* 상단 메뉴 */}
               <WAppBar
                  onLogout={() => {
                     category === 'my' && UIAction.SetCategory('all');
                  }}
               />
               {/* 제목 */}
               <Box p={1} />
               {/* <WTitle>{lang.w.제목}</WTitle> */}
               <img
                  src={
                     langM.GetCurrentLanguage() === 'ko'
                        ? './logo2_1024x238_ko.png'
                        : './logo2_1024x238_en.png'
                  }
                  alt={''}
                  width={300}
                  style={{
                     paddingLeft: 15,
                     paddingRight: 15,
                     maxWidth: '100%',
                     animation: 'aniZoomIn 0.4s ease-in-out',
                  }}
               />
               <WSubTitle sx={{ fontSize: '1em', p: 0 }}>{lang.w.개요.toUpperCase()}</WSubTitle>
               {/* 내 방 목록 */}
               <Box pb={1} pt={2}>
                  <WMenu category="all">{lang.common.all}</WMenu>
                  <WMenu category="exhibition">{lang.common.exhibition}</WMenu>
                  <WMenu category="event">{lang.common.event}</WMenu>
                  <WMenu category="homepage">{lang.common.homepage}</WMenu>
                  {/* <WMenu category="game">{lang.common.game}</WMenu> */}
                  <WMenu category="etc">{lang.common.etc}</WMenu>
                  <Box display={'inline-block'} pl={0.3} pr={0.3}>
                     |
                  </Box>
                  <WMenu category="my">{lang.w.내월드}</WMenu>
                  {/* <WMenu category="business">
                        {lang.common.회사소개}
                     </WMenu>  */}
               </Box>
               {/* 내 방 목록 */}
               {/* <WPageMyRoomList /> */}
               {/* 방 목록 */}
               {/* <Box p={1} /> */}
               <WPageRoomList category={category} />
               {/* 어바웃 */}
               <Box p={1} />
               <WPageAbout />
               <Box m={1} sx={{ textAlign: 'center' }}>
                  <Box>
                     <img
                        style={{ position: 'relative', top: 1, margin: 5 }}
                        height={30}
                        src={'./logo2_256x128.png'}
                        alt=""
                     />
                  </Box>
                  <Box p={1} fontSize={'0.8em'}>
                     {lang.w.주소}
                  </Box>
               </Box>
            </Box>
            {/* 메뉴 */}
            {/* {RenderReactiveMenu()} */}
         </Stack>
         {/* 푸터 */}
         <CConsole showTasks useConsole />
      </Stack>
   );
};

export const WMain = memo(() => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   React.useEffect(() => {
      init();
      return () => {
         window.removeEventListener('resize', HandleResize);
         window.removeEventListener('keydown', HandleKeydown);
         window.removeEventListener('message', HandlePostMessage);
      };
   }, []);

   let pathParameter: string = window.location.pathname;
   function Render() {
      let path = pathParameter.split('/').filter((x) => x.length > 0);
      // console.log(path);
      if (path.length > 0) {
         let pageType = path[0];
         let roomID = path[1];
         let mode = path[2] ?? 'play';
         if (pageType === 'embed' && roomID) {
            return (
               <Box className={ModalM.IsOpen() || MsgBoxM.IsOpen() ? 'blur' : undefined}>
                  <iframe
                     id={roomID}
                     title={roomID}
                     frameBorder={0}
                     width="100%"
                     // height="100%"
                     height={window.innerHeight}
                     style={{ borderRadius: 5 }}
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                     allowFullScreen
                     src={`${MyStringUtil.GetHostURL()}/worldmaker/loader.html?mode=${mode}&window=embed&room=${roomID}`}
                  ></iframe>
               </Box>
            );
         }
      }
      //기본
      return <WMain2 />; //루트페이지
   }

   return (
      <MetaMaskProvider>
         <ThemeProvider theme={w_theme}>
            <CssBaseline />
            <MsgBox
               data={useSelector((state: RootStateType) => state.modal)}
               onClose={() => ModalM.Close()}
            />
            <MsgBox data={useSelector((state: RootStateType) => state.msg)} onClose={() => MsgBoxM.Close()} />
            <CNotificationStack />
            {Render()}
         </ThemeProvider>
      </MetaMaskProvider>
   );
});

async function init() {
   // 초기화
   MyDebug.LogClear();
   MyDebug.Log(`${config.TITLE}`);
   MyDebug.Log(MyStringUtil.FormatDateTime(new Date()));
   MyDebug.Log('User Agent: ' + navigator.userAgent);

   // 제목 변경
   document.title = lang.w.제목;
   // description 변경
   document.querySelector('meta[name="description"]')?.setAttribute('content', lang.w.어바웃01);

   // API 초기화
   APIBase.Init(WAccount.s, config.URL_API_SERVER);

   // 키 입력
   window.addEventListener('resize', HandleResize);
   window.addEventListener('keydown', HandleKeydown);
   window.addEventListener('message', HandlePostMessage);

   // 파이어베이스 초기화
   const firebaseConfig: FirebaseOptions = {
      apiKey: 'AIzaSyDB0uaz8zpZMEhaWNYByRCeI2Loo6fja3k',
      authDomain: 'my-world-maker.firebaseapp.com',
      projectId: 'my-world-maker',
      storageBucket: 'my-world-maker.appspot.com',
      messagingSenderId: '150714713192',
      appId: '1:150714713192:web:73446593fa8211a5e7a7dd',
      measurementId: 'G-894QKHBVPK',
   };
   await MyFirebaseM.Init(firebaseConfig);

   // 게임 초기화
   await WRepository.Init();

   MySystem.Init();
}

function HandleResize() {
   MySystem.ForceUpdate();
}

function HandleKeydown(e: any) {
   //! 단축키 처리
   if (MySystem.IsDevelopBuild()) {
      if (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) {
         if (!e.repeat) {
            if (config.DEBUG && e.key === 'i') {
               //ctrl+i
               //변수 목록
               e.preventDefault();
               WSystem.ShowAllVariables();
            } else if (e.key === 'd') {
               //ctrl+d
               //디버깅 온오프
               e.preventDefault();
               config.DEBUG = !config.DEBUG;
               MySystem.ForceUpdate();
            }
         }
      }
   }
}

async function HandlePostMessage(event: MessageEvent) {
   function _PostMessageCallback(evt: MessageEvent, data: any) {
      evt.source?.postMessage({ callbackId: evt.data.callbackId, data: data }, { targetOrigin: evt.origin });
   }

   if (event.data?.type) {
      let type = event.data.type;
      let data = event.data.data;
      console.log('포스트메세지 처리:', type, data);

      switch (type) {
         case 'Init':
            _PostMessageCallback(event, 'ok');
            break;
         case 'FullScreen':
            ToggleFullScreen(data.iframeId);
            _PostMessageCallback(event, 'ok');
            break;
         case 'MsgBox': {
            let ret = await MsgBoxM.Open(data.msg, data.ok, data.cancel);
            _PostMessageCallback(event, ret);
            break;
         }
         case 'UserInfo': {
            let uid = data.uid;
            await WSystem.OpenUserInfoWindowById(uid);
            _PostMessageCallback(event, 'ok');
            break;
         }
         case 'ImageViewer': {
            await WSystem.OpenImageViewer(data);
            _PostMessageCallback(event, 'ok');
            break;
         }
         case 'ImageSelect': {
            break;
         }
      }
   }
}

function ToggleFullScreen(iframeId: string) {
   let win: any = window;
   let targetWin = window; //win.parent;
   try {
      let myIframe = targetWin.document.getElementById(iframeId);
      if (myIframe) {
         if (targetWin.document.fullscreenElement) {
            console.log('풀스크린:종료요청');
            targetWin.document.exitFullscreen();
         } else {
            //리스너 등록
            if (!win.myFullscreenChangeEventListener) {
               win.myFullscreenChangeEventListener = true;
               targetWin.document.addEventListener('fullscreenchange', function () {
                  // iframe 복원
                  if (win.myIframesRollback && !targetWin.document.fullscreenElement) {
                     console.log('풀스크린:원래창으로복원');
                     let iframes = win.myIframesRollback;
                     for (let i = 0; i < win.myIframesRollback.length; i++) {
                        // Object.assign(
                        //    win.myIframesRollback[i].style,
                        //    win.myIframesRollback[i].rollbackStyle,
                        // );
                        // win.myIframesRollback[i].style = win.myIframesRollback[i].rollbackStyle;
                        iframes[i].style.position = iframes[i].rollbackStyle.position;
                        iframes[i].style.top = iframes[i].rollbackStyle.top;
                        iframes[i].style.left = iframes[i].rollbackStyle.left;
                        iframes[i].style.width = iframes[i].rollbackStyle.width;
                        iframes[i].style.height = iframes[i].rollbackStyle.height;
                        iframes[i].style.zIndex = iframes[i].rollbackStyle.zIndex;
                        iframes[i].style.visibility = iframes[i].rollbackStyle.visibility;
                        iframes[i].style.display = iframes[i].rollbackStyle.display;
                     }
                     win.myIframesRollback = null;
                     win.myFullScreenMode = null;
                  }
               });
            }
            //풀스크린 실행
            if (!win.myFullScreenMode) {
               // 복원할 iframe 리스트 저장
               let iframes = targetWin.document.getElementsByTagName('iframe');
               if (iframes) {
                  for (let i = 0; i < iframes.length; i++) {
                     let frame: any = iframes[i];
                     frame.rollbackStyle = {};
                     frame.rollbackStyle.position = iframes[i].style.position;
                     frame.rollbackStyle.top = iframes[i].style.top;
                     frame.rollbackStyle.left = iframes[i].style.left;
                     frame.rollbackStyle.width = iframes[i].style.width;
                     frame.rollbackStyle.height = iframes[i].style.height;
                     frame.rollbackStyle.zIndex = iframes[i].style.zIndex;
                     frame.rollbackStyle.visibility = iframes[i].style.visibility;
                     frame.rollbackStyle.display = iframes[i].style.display;
                     if (iframes[i] !== myIframe) {
                        //모든 iframe 숨기기
                        if (iframes[i].style.visibility !== 'hidden') {
                           iframes[i].style.visibility = 'hidden';
                        }
                        if (iframes[i].style.display !== 'none') {
                           iframes[i].style.display = 'none';
                        }
                     }
                  }

                  // 아래에서 하면 안됨 (myIframe의 속성이 변해서 측정이 안됨))
                  let isFitScreen = targetWin.innerHeight === myIframe.offsetHeight;

                  win.myIframesRollback = iframes;
                  myIframe.style.position = 'fixed';
                  myIframe.style.top = '0px';
                  myIframe.style.left = '0px';
                  myIframe.style.width = '100vw';
                  //100vh나 window.innerHeight 둘다 안됨
                  //매번 window.addEventListener("resize", handleResize); 에서 window.innerHeight를 갱신해줄수도 없고
                  myIframe.style.zIndex = '1';

                  if (isFitScreen) {
                     myIframe.style.height = `100vh`;
                     win.myFullScreenMode = 2;
                     targetWin.document.body.requestFullscreen();
                     console.log('풀스크린:최종');
                  } else {
                     myIframe.style.height = '-webkit-fill-available';
                     win.myFullScreenMode = 1;
                     console.log('풀스크린:중간');
                  }
               }
            } else if (win.myFullScreenMode === 1) {
               myIframe.style.height = `100vh`;
               win.myFullScreenMode = 2;
               // myIframe.requestFullscreen();
               targetWin.document.body.requestFullscreen();
               console.log('풀스크린:최종');
            }
         }
         return;
      }
   } catch (e) {}
}
