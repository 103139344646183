import { MyUtil } from '../../base/MyUtil';
import { ActionMsgBox, IMsgBox, IMsgBoxInput } from './MsgBoxReducer';

export type MsgBoxReturn = {
   btn: string;
   input?: any[]; //순서대로 정렬된 입력값
};

/**
 * 메시지 박스 매니저
 * @author elee
 * @date 2021-03-15,
 */
export class MsgBoxM {
   static list: IMsgBox[] = [];
   static lastData: IMsgBox | undefined = undefined;

   /** 
    * 메시지: 비동기 식 
    * 메시지가 이미 열려 있으면 큐에 넣어두고 닫을때 실행된다.
   */
   static async Open(
      label: any,
      btnOK?: string,
      btnCancel?: string,
      input?: IMsgBoxInput[],
      block?: boolean,
      autoOK?: boolean,
   ): Promise<MsgBoxReturn> {
      if (autoOK) return { btn: btnOK || 'OK' };

      //창 닫기
      let ret: any = undefined;

      //메시지 박스
      this.MsgBoxSync(
         label,
         btnOK,
         btnCancel,
         (r) => {
            ret = r;
         },
         input,
         block,
      );

      //클릭 콜백 대기
      await MyUtil.WaitUntil(() => {
         return !!ret;
      });
      
      return ret;
   }

   /** 메시지 박스 열기 */
   private static MsgBoxSync(
      label?: any,
      btnOK?: string,
      btnCancel?: string,
      cb?: (ret: MsgBoxReturn) => void,
      input?: IMsgBoxInput[],
      block?: boolean,
   ) {
      //새 데이터 푸시
      this.list.push({
         children: label,
         btnOK: btnOK,
         btnCancel: btnCancel,
         cb: cb,
         isOpen: true,
         input: input,
         block: block,
      });

      // 데이터 적용
      this.Deque();
   }

   /** 열려 있는가? */
   static IsOpen(): boolean {
      return !!this.lastData;
   }

   /** 큐 적용 */
   private static Deque() {
      // 열려 있는 데이터가 있으면, 닫을때 다시 적용한다.
      if (!this.lastData && this.list.length > 0) {
         this.lastData = this.list.shift(); // 데이터리스트 가져와서 (순차적으로 가져오기 위해 pop 사용하면 안됨)
         if(this.lastData) ActionMsgBox.SetMsgBox(this.lastData); //보여줌
      }
   }

   /** 닫기 */
   static Close(btn: string = 'None') {
      // 콜백 호출
      if (this.lastData) {
         if (this.lastData.cb) {
            this.lastData.cb({ btn: btn });
         }
         this.lastData = undefined;

         // 클로즈 리덕스 호출
         ActionMsgBox.SetMsgBox({ isOpen: false });

         // 다음 데이터 적용
         this.Deque();
      }
   }
}
