import { Box, BoxProps, Stack } from '@mui/material';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { lang } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { WSubTitle } from '../../w/components/WTitle';

export const PPageAbout = memo((props: BoxProps) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   return (
      <Box pl={2} pr={2} {...props}>
         {/* 제목 */}
         {/* <WSubTitle>
            <Stack direction={'row'} justifyContent={'center'} spacing={1} alignItems={'center'}>
               <img width={30} height={30} src={'./globe.png'} alt="" />
               <Box>{lang.common.어바웃}</Box>
            </Stack>
         </WSubTitle> */}
         <Box sx={{ textAlign: 'center' }}>
            <Box m={1.5}>{lang.p.어바웃01}</Box>
            <Box m={1.5}>{lang.p.어바웃02}</Box>
            <Box m={1.5}>{lang.p.어바웃03}</Box>
            <Box m={1.5}>{lang.p.어바웃04}</Box>
            <Box m={2} textAlign={'center'}>
               {lang.w.어바웃05}
            </Box>
            {/* 유튜브 영상 */}
            {/* <Box m={5} textAlign={'center'}>
               <iframe
                  style={{ maxWidth: '100%', margin: '2px' }}
                  // src="https://www.youtube.com/embed/-BrgVHK1OVo?autoplay=1&mute=1"
                  src="https://www.youtube.com/embed/OWVyum49cSA"
                  frameBorder={0}
                  width="380"
                  height="240"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
               ></iframe>
               <iframe
                  style={{ maxWidth: '100%', margin: '2px' }}
                  // src="https://www.youtube.com/embed/-BrgVHK1OVo?autoplay=1&mute=1"
                  src="https://www.youtube.com/embed/s2MuFQTg5xA"
                  frameBorder={0}
                  width="380"
                  height="240"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
               ></iframe>
            </Box> */}
         </Box>
         <Box>
               {lang.common.사업문의} : <a href="mailto:evan.lee@plusgonow.com">evan.lee@plusgonow.com</a>
            {/* <Box display={'inline-block'} m={1}>
               |
            </Box> */}
            {/* <Box display={'inline-block'}>
               <a
                  href="https://docs.google.com/presentation/d/1VI8Bu03JTOVzuYOnfUQb8dNYYH4nhYM1siWXvsP2dAE/edit?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  {lang.common.사업계획서}
               </a>
            </Box> */}
         </Box>
      </Box>
   );
});
