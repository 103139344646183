import { Close } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Modal, Select, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { RefObject, useState } from 'react';
import { MyStringUtil } from '../../base/MyStringUtil';
import { lang, langM } from '../../config/lang';
import { CButton } from '../CButton';
import { ToastM } from '../toast/ToastM';
import { IMsgBox } from './MsgBoxReducer';

const useStyles = makeStyles((theme: Theme) => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10002,
      width: '100%',
      height: '100%',
   },
   frame: {
      borderRadius: theme.shape.borderRadius,
      overflow: 'auto',
      maxHeight: 'calc(100% - 20px)',
      maxWidth: 'calc(100% - 20px)',
      padding: '15px',
      paddingBottom: '5px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, .3)',
      animation: 'aniZoomIn 0.3s ease-in-out',
      // zIndex: 10002,
   },
   frameFullScreen: {
      // overflow: 'auto',
      animation: 'aniFadeIn 0.3s ease-in-out',
      // backgroundColor: 'red',
   },
   content: {
      // overflow: 'auto',
      // width: '100%',
      // height: '100%',
      // backgroundColor: 'red',
      // zIndex: 10002,
      textAlign: 'center',
   },
}));

/**
 * 메시지 박스
 * @author elee
 * @date 2021-04-12,
 */
export function MsgBox(props: { data: IMsgBox; onClose: () => void }) {
   const data = props.data;

   const open: boolean = Boolean(data.isOpen);
   const [update, setUpdate] = useState<boolean>(false);
   const inputRef: RefObject<HTMLInputElement>[] = [
      React.useRef<HTMLInputElement>(null),
      React.useRef<HTMLInputElement>(null),
      React.useRef<HTMLInputElement>(null),
      React.useRef<HTMLInputElement>(null),
   ];

   const classes = useStyles();

   /** 버튼 눌러서 닫기 */
   function handleClose(btn?: string) {
      btn = btn || 'None';
      let inputs: any[] = [];
      if (btn === data.btnOK) {
         //입력값 가져오기
         if (Array.isArray(data.input)) {
            for (let i = 0; i < data.input.length; i++) {
               if (inputRef[i]) {
                  if (data.input[i].type === 'file') {
                     // @ts-ignore
                     inputs[i] = inputRef[i].current.files[0]; //파일 가져오기
                  } else {
                     inputs[i] = inputRef[i].current?.value.trim(); //값 가져오기
                  }
                  if (!inputs[i]) {
                     ToastM.Open(lang.error.value_is_empty, 'error');
                     return;
                  }
               }
            }
         }
      }

      //직접 닫았을 경우 콜백
      props.onClose();

      //콜백
      if (data.cb) {
         if (Array.isArray(data.input)) data.cb({ input: inputs, btn: btn });
         else data.cb({ btn: btn });
      }
   }

   /** 배경 눌러서 닫기 */
   function handleCloseNoButton() {
      if (!data.block) {
         handleClose('None');
      }
   }

   let i = 0;
   return (
      <>
         {/* 블러 배경 */}
         {/* {open && <Box className={'blur'} sx={{
            width: '100vw',
            height: '100vh',
         }}>
         </Box>} */}
         {/* 모달 */}
         <Modal
            className={classes.modal}
            open={open}
            onClose={handleCloseNoButton}
            closeAfterTransition
            BackdropComponent={undefined}
            BackdropProps={{
               timeout: 300,
               style: { backgroundColor: 'rgba(0,0,0,0.2)' },
            }}
         >
            {/*<Fade in={open}>*/}
            {/*{data.children ? data.children : null}*/}
            {/*테두리*/}
            <Box className={data.fullScreen ? classes.frameFullScreen : classes.frame}>
               {/* box를 relative로 하고 버튼을 absolute로 하면 부모 기준으로 absolute가 된다. */}
               {!data.fullScreen && (
                  <Box sx={{ position: 'relative' }}>
                     {/* 클로즈 버튼 */}
                     <IconButton
                        sx={{ position: 'absolute', right: -10, top: -10 }}
                        onClick={() => {
                           handleClose('None');
                        }}
                     >
                        <Close />
                     </IconButton>
                  </Box>
               )}
               <Box className={classes.content}>
                  <Box>
                     {/*컨텐트(string 타입은 특별 처리)*/}
                     {typeof data.children === 'string' ? (
                        <Typography pt={2}>{data.children}</Typography>
                     ) : (
                        data.children
                     )}
                     {/*입력창*/}
                     {data.input?.map((input) =>
                        input.type === 'list' ? (
                           <Select
                              label={MyStringUtil.Capitalize(input.id)}
                              value={input.val}
                              inputRef={inputRef[i++]}
                              fullWidth
                              color="secondary"
                              onChange={(evt: any) => {
                                 input.val = evt.target.value;
                                 setUpdate(!update);
                              }}
                           >
                              {input.list
                                 ? input.list.map((l) => (
                                      <MenuItem value={l}>{langM.GetLocalizedText('label', l)}</MenuItem>
                                   ))
                                 : undefined}
                           </Select>
                        ) : (
                           <TextField
                              margin="dense"
                              label={MyStringUtil.Capitalize(input.id)}
                              value={input.val}
                              type={input.type}
                              inputRef={inputRef[i++]}
                              fullWidth
                              color="secondary"
                              onChange={(evt: any) => {
                                 input.val = evt.target.value;
                                 setUpdate(!update);
                              }}
                           />
                        ),
                     )}
                  </Box>
                  {/*버튼*/}
                  <Box style={{ textAlign: 'right' }}>
                     {/*<Container>*/}
                     {data.btnOK ? (
                        <CButton
                           style={{ margin: 5 }}
                           color="primary"
                           onClick={() => handleClose(data.btnOK)}
                           children={data.btnOK}
                        />
                     ) : null}
                     {data.btnCancel ? (
                        <CButton
                           style={{ margin: 5 }}
                           color="primary"
                           onClick={() => handleClose(data.btnCancel)}
                           children={data.btnCancel}
                        />
                     ) : null}
                  </Box>
               </Box>
            </Box>
            {/*</Fade>*/}
         </Modal>
      </>
   );
}
