import * as fbAnalytics from 'firebase/analytics';
import * as fbApp from 'firebase/app';
import { FirebaseOptions } from 'firebase/app';
import * as fbAuth from 'firebase/auth';
import * as fbFirestore from 'firebase/firestore';
import * as fbMessaging from 'firebase/messaging';

import { MsgBoxM } from '../components/msgbox/MsgBoxM';
import { config } from '../config/config';
import { lang, langM } from '../config/lang';
import { MyDebug } from '../util/MyDebug';
import { MySystem } from '../util/MySystem';
import { MyTaskM } from './MyTaskM';

//myworldmaker.com
// export interface FirebaseConfig {
//    apiKey: '';
//    authDomain: '';
//    projectId: '';
//    storageBucket: '';
//    messagingSenderId: '';
//    appId: '';
//    measurementId: '';
// }

//plusdata.kr
// const firebaseConfig = {
//    apiKey: "AIzaSyDqON-nl0SRdE2oUWWiG-KDlUDc7cJWD8s",
//    authDomain: "plusdata-506c0.firebaseapp.com",
//    projectId: "plusdata-506c0",
//    storageBucket: "plusdata-506c0.appspot.com",
//    messagingSenderId: "609795996691",
//    appId: "1:609795996691:web:278803a16ddc2d1a18d3e6",
//    measurementId: "G-W0G20JLWL2"
//  };

export class MyFirebaseM {
   static #app?: fbApp.FirebaseApp;
   static #db: fbFirestore.Firestore;
   static #analytics: fbAnalytics.Analytics;
   static #messaging: fbMessaging.Messaging;
   static #auth: fbAuth.Auth;

   static get user() {
      return this.#auth?.currentUser;
   }

   static async Init(config: FirebaseOptions) {
      console.log('MyFirebaseM.초기화');

      this.#app = fbApp.initializeApp(config);
      this.#db = fbFirestore.getFirestore();
      this.#analytics = fbAnalytics.getAnalytics();
      this.#auth = fbAuth.getAuth();
      this.#messaging = fbMessaging.getMessaging(this.#app);

      // 리다이렉션 결과 받음
      if (!this.user) {
         await MyDebug.Task(lang.common.초기화, async () => {
            let result = await fbAuth.getRedirectResult(this.#auth);
            if (result) console.log('리다이렉션 결과 받음');
         });
      }

      // console.log(this.user);
   }

   static async Login() {
      if (MySystem.IsWebView()) {
         let ret = await MsgBoxM.Open(
            lang.common.현재브라우저에서는로그인할수없습니다,
            lang.common.기본웹브라우저로열기,
            lang.common.cancel,
         );
         if (ret.btn === lang.common.기본웹브라우저로열기) {
            // 다른 브라우저로 열기
            MyTaskM.Start(lang.common.리다이렉트);
            MySystem.OpenUrl(config.URL_OPEN_REDIRECTION);
         }
         return;
      }
      //리다이렉션 로그인
      const provider = new fbAuth.GoogleAuthProvider();
      this.#auth.languageCode = langM.GetCurrentLanguage();
      fbAuth.signInWithRedirect(this.#auth, provider);
   }

   static async Logout() {
      await MyDebug.Task(lang.common.동기화, async () => {
         await this.#auth.signOut();
      });
   }

   static async DBAdd(path: string, ...data: any[]) {
      let ret: string[] = [];
      await MyDebug.Task(lang.common.동기화, async () => {
         let q = fbFirestore.collection(this.#db, path);
         for (let d of data) {
            let docRef = await fbFirestore.addDoc(q, {
               ...d,
               createdAt: fbFirestore.serverTimestamp(),
               updatedAt: fbFirestore.serverTimestamp(),
            });
            ret.push(docRef.id);
         }
      });
      return ret;
   }

   // static async DBSetDoc(path: string, uid: string, data: any, create = false) {
   //    await MyDebug.Task(lang.common.정보, async () => {
   //       if (create) {
   //          data.createdAt = fbFirestore.serverTimestamp();
   //       }
   //       let q = fbFirestore.doc(this.#db, path, uid);
   //       await fbFirestore.setDoc(q, {
   //          ...data,
   //          updatedAt: fbFirestore.serverTimestamp(),
   //       });
   //    });
   // }

   static async DBUpdate(path: string, uid: string, data: any) {
      await MyDebug.Task(lang.common.동기화, async () => {
         let q = fbFirestore.doc(this.#db, path, uid);
         await fbFirestore.setDoc(
            q,
            {
               ...data,
               updatedAt: fbFirestore.serverTimestamp(),
            },
            { merge: true },
         );
      });
   }

   static async DBFindOne(path: string, uid: string): Promise<{ uid: string; data: any } | undefined> {
      let ret: { uid: string; data: any } | undefined = undefined;
      await MyDebug.Task(lang.common.동기화, async () => {
         let q = fbFirestore.doc(this.#db, path, uid);
         const snapShot = await fbFirestore.getDoc(q);
         if (snapShot && snapShot.exists()) ret = { uid: snapShot.id, data: snapShot.data() ?? {} };
      });
      return ret;
   }

   static async DBFindMany(path: string, ...uid: string[]) {
      let ret: { uid: string; data?: fbFirestore.DocumentData }[] = [];
      await MyDebug.Task(lang.common.동기화, async () => {
         let q = fbFirestore.collection(this.#db, path, ...uid);
         const snapShot = await fbFirestore.getDocs(q);
         ret = snapShot.docs.map((doc) => {
            return { uid: doc.id, data: doc.data() };
         });
      });
      return ret;
   }

   static async DBFindWhere(path: string, ...where: fbFirestore.QueryConstraint[]) {
      if (where === undefined) where = [];
      let ret: { uid: string; data: fbFirestore.DocumentData }[] = [];
      await MyDebug.Task(lang.common.동기화, async () => {
         const q = fbFirestore.query(fbFirestore.collection(this.#db, path), ...where!);
         const snapShot = await fbFirestore.getDocs(q);
         ret = snapShot.docs.map((doc) => {
            return { uid: doc.id, data: doc.data() };
         });
      });
      return ret;
   }

   static async DBDeleteDoc(path: string, uid: string) {
      await MyDebug.Task(lang.common.동기화, async () => {
         let q = fbFirestore.doc(this.#db, path, uid);
         await fbFirestore.deleteDoc(q);
      });
   }

   static async DBDeleteDocsWhere(path: string, ...where: fbFirestore.QueryConstraint[]) {
      await MyDebug.Task(lang.common.동기화, async () => {
         const q = fbFirestore.query(fbFirestore.collection(this.#db, path), ...where);
         const snapShot = await fbFirestore.getDocs(q);
         snapShot.docs.forEach(async (doc) => {
            await fbFirestore.deleteDoc(doc.ref);
         });
      });
   }
}
