import { MyUtil } from '../base/MyUtil';
import { UIAction } from '../reducer/UIReducer';
import { MyDebug } from '../util/MyDebug';
import { MySystem } from '../util/MySystem';

export class TaskData {
   msg?: string = '';
   handle: string = '';
   progress: number = 0;
   logId?: number;
}

export class MyTaskM {
   private static tasks: TaskData[] = [];

   /** 태스크 시작 */
   static Start(handle: string, silent?: boolean, msg?: string): boolean {
      // let logID = -1;
      handle = handle.toLowerCase();
      let task = this.FindTask(handle);
      if (task) return false; //이미 존재

      task = new TaskData();
      task.handle = handle;
      task.msg = msg ?? handle;
      this.tasks.push(task);

      if (!silent) task.logId = MyDebug.Log(`Task: ${task.msg}`); //로그 남김
      task.progress = 0;
      UIAction.SetTaskCount(this.tasks.length);
      MySystem.ForceUpdate();
      return true;
   }

   /** 태스크 진행 */
   static Process(handle: string, progress: number = 100) {
      handle = handle.toLowerCase();
      let task = this.tasks.find((t) => t.handle === handle);
      if (task) {
         // const lerp = (task.progress + progress) / 2;
         //logID = 0도 처리해야 하므로 명확하게 비교해 준다
         if (task.logId !== undefined) {
            // // 자연스럽게 보이기 위해 중간값을 한번 갱신한다. (태스크에는 중간값이 안찍히는데)
            // await MySystem.Sleep(100);
            // task.progress = lerp;
            // MyDebug.Log(`${task.name} ${task.progress}%`, 'info', task.logID);
            // await MySystem.Sleep(100);
            task.progress = progress;
            MyDebug.Log(`Task: ${task.msg} End`, task.logId);
            // await MySystem.Sleep(200);
         }
         if (progress === 100) {
            MyUtil.ArrayRemoveItem(this.tasks, task);
            UIAction.SetTaskCount(this.tasks.length);
         }
      }
      MySystem.ForceUpdate();
   }

   /** n초 뒤 태스크 강제 종료 **/
   static async ForceClose(handle: string, sleep: number = 5000) {
      let task = this.tasks.find((t) => t.handle === handle);
      if (task) {
         await MyUtil.Wait(sleep);
         MyUtil.ArrayRemoveItem(this.tasks, task);
         UIAction.SetTaskCount(this.tasks.length);
      }
   }

   static GetTasks(): TaskData[] {
      return this.tasks;
   }

   static HasTask(handle?: string) {
      if (handle === undefined) return this.tasks.length > 0;
      return !!this.FindTask(handle);
   }

   static Empty() {
      return this.tasks.length === 0;
   }

   static FindTask(handle: string) {
      return this.tasks.find((t) => t.handle === handle);
   }
}
