// import { v4 } from 'uuid';

export class MyUtil {
   /** 배열에서 아이템 삭제 */
   static ArrayRemoveItem(array: any[], item: any) {
      const index = array.indexOf(item);
      if (index !== -1) {
         array.splice(index, 1);
      }
   }

   /** 시간 대기 */
   static async Wait(millisec = 1) {
      return new Promise((resolve) => setTimeout(resolve, millisec));
   }

   /** 조건 대기 */
   static async WaitUntil(cb: () => boolean) {
      while (true) {
         if (cb()) break;
         await MyUtil.Wait(100);
      }
   }

   /** 조건 대기 */
   static async WaitWhile(cb: () => boolean) {
      while (true) {
         if (!cb()) break;
         await MyUtil.Wait(100);
      }
   }

   /**
    * 일반 오브젝트를 클래스 오브젝트로 변환
    * - 용법:
    * MyUtil.CastObject<Workspace>(JSON.parse(json), Workspace.prototype);
    */
   static CastObjectToClass<T>(obj: any, prototype: T) {
      obj.__proto__ = prototype;
      return obj as T;
   }

   /** 배열 마지막 요소 가져오기 (pop 는 삭제해 버리므로..) */
   static ArrayLastElement<T>(array: Array<T>) {
      if (array.length > 0) return array[array.length - 1];
   }

   /** 리스트에서 중복된 아이템들을 제거한다 */
   static ArrayRemoveDuplicate<T>(array: T[]) {
      // @ts-ignore
      return [...new Set(array)];
   }

   /** 특정 키밸류를 가지고 있는 오브젝트(자식포함) 찾기 */
   static FindObjectByKeyValue(findKey: string, findVal: string, obj: any): any {
      for (let key in obj) {
         const oojs = obj[key];
         //발견
         if (key === findKey && oojs === findVal) return obj;
         //자식 탐색
         if (Array.isArray(oojs)) {
            for (let child of oojs) {
               const ret = this.FindObjectByKeyValue(findKey, findVal, child);
               if (ret) return ret;
            }
         }
      }
   }

   /** 객체 복제 */
   static DeepCloneObject(original: any) {
      return JSON.parse(JSON.stringify(original));
   }

   /** 함수형이면 실행해서 리턴 */
   static GetFunctionToObject(component: any) {
      return typeof component === 'function' ? component() : component;
   }

   /** undefined 로 캐스팅 */
   static Undefined(value: any) {
      return value ?? undefined;
   }
}
