import { ButtonProps } from '@mui/material';
import { memo } from 'react';
import { CButton } from '../../common/components/CButton';

interface WMenuButtonProps extends ButtonProps {
   selected?: boolean;
   icon?: any;
}

export const WMenuButton = memo((props: WMenuButtonProps) => {
   // let theme = useTheme();
   return (
      <CButton
         sx={{ fontSize: '1.1em' }}
         variant={props.selected ? 'contained' : 'text'}
         startIcon={props.icon}
         color={'primary'}
         // startIcon={<Add />}
         onClick={(e) => {
            props.onClick?.(e);
         }}
      >
         {props.children}
      </CButton>
   );
});

