import { Delete, PlayArrow } from '@mui/icons-material';
import { Box, FormLabel, MenuItem, Select } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { CButton } from '../../common/components/CButton';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { ToastM } from '../../common/components/toast/ToastM';
import { ValidatedTextField } from '../../common/components/validate/ValidatedTextField';
import { lang } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { UIAction } from '../../common/reducer/UIReducer';
import { MySystem } from '../../common/util/MySystem';
import { WSubTitle } from '../components/WTitle';
import { WMapInfoData, WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';

// let inputValuesCheck: any = {};
let inputValues = resetValues();
function resetValues() {
   return {
      title: {
         value: '',
         error: false,
         value0: '',
      },
      desc: {
         value: '',
         error: false,
         value0: '',
      },
      map: {
         value: 'mapBlock001',
         error: false,
         value0: '',
      },
      size: {
         value: 'size50',
         error: false,
         value0: '',
      },
      category: {
         value: 'etc',
         error: false,
         value0: '',
      },
      visibility: {
         value: 'private',
         error: false,
         value0: '',
      },
   };
}

export const WPageCreateRoom = memo(
   (props: {
      mapId?: string;
      map?: WMapInfoData;
      copyMapId?: string;
      category?: string;
      cbEditWorld?: () => void;
   }) => {
      useSelector((state: RootStateType) => state.ui.forceUpdate);
      const [refresh, setRefresh] = useState(false);
      const [showError, setShowError] = useState(false);
      const isDataChanged = !!GetSaveData();
      const clone = !props.mapId && props.map && props.copyMapId;

      useEffect(() => {
         console.log('WPageCreateRoom.init' + props.category);
         inputValues = resetValues();
         if (props.map) {
            //수정 또는 복제 생성
            inputValues.title.value0 = inputValues.title.value = props.map.title ?? '';
            inputValues.desc.value0 = inputValues.desc.value = props.map.desc ?? '';
            if (props.map.map) inputValues.map.value0 = inputValues.map.value = props.map.map;
            if (props.map.size) inputValues.size.value0 = inputValues.size.value = props.map.size;
            if (props.map.category)
               inputValues.category.value0 = inputValues.category.value = props.map.category;
            if (props.map.visibility)
               inputValues.visibility.value0 = inputValues.visibility.value = props.map.visibility;
            if (clone) {
               //복제 생성
               inputValues.title.value = inputValues.title.value + ' (copy)';
               inputValues.visibility.value = 'private';
               inputValues.desc.value0 = '';
               inputValues.map.value0 = '';
               inputValues.size.value0 = '';
               inputValues.category.value0 = '';
               inputValues.visibility.value0 = '';
            }
         } else if (props.category) {
            //신규 생성 (카테고리 지정)
            if (!(props.category === 'all' || props.category === 'my'))
               inputValues.category.value = props.category;
         }
         setRefresh(!refresh);
      }, []);

      function GetSaveData() {
         let saveData: any = null;
         for (const key in inputValues) {
            let v = (inputValues as any)[key];
            if (v.value !== v.value0) {
               if (saveData === null) saveData = {};
               saveData[key] = v.value;
            }
         }
         return saveData;
      }

      async function Submit() {
         setShowError(true);
         // 입력값 검증
         for (const key in inputValues) {
            if ((inputValues as any)[key].error) {
               ToastM.Open(lang.common.입력값을확인하세요, 'error');
               return;
            }
         }

         // 창 닫기
         ModalM.Close();

         // 변경값이 있으면 저장
         // let newInputValuesCheck =
         //    inputValues.title.value + inputValues.desc.value + inputValues.map.value + inputValues.size.value;
         let dataSave = GetSaveData();
         if (dataSave) {
            if (props.mapId) {
               // *변경 사항 저장
               const dataSave2 = {
                  ...dataSave,
                  // owner: WRepository.user?.uid ?? '',
                  ownerName: WRepository.user?.displayName ?? '',
                  ownerPhoto: WRepository.user?.photoURL ?? '',
                  // editor: [],
               };
               await WRepository.SaveMapInfo(props.mapId, dataSave2);
            } else {
               // *맵 새로 만들기
               const dataSave2 = {
                  ...dataSave,
                  owner: WRepository.user?.uid ?? '',
                  ownerName: WRepository.user?.displayName ?? '',
                  ownerPhoto: WRepository.user?.photoURL ?? '',
                  editor: [],
                  sort: 0,
               };
               // 맵 데이터 복사
               let deco = {};
               // 클론이면
               if (props.copyMapId) {
                  //dataSave2.deco = props.map.deco;
                  let srcMapData = await WRepository.GetMapData(props.copyMapId);
                  if (srcMapData) {
                     deco = srcMapData.data.deco;
                  }
               }
               let mapId = await WRepository.CreateMap(dataSave2, deco);
               if (mapId) {
                  ToastM.Open(MyStringUtil.Format(lang.w.xxx월드가생성되었습니다, dataSave2.title));
                  UIAction.SetCategory('my');
                  //WSystem.OpenRoomInfoWindow(mapId, dataSave2);
               }
            }
            await WRepository.LoadMapList();
            MySystem.ForceUpdate();
         }
      }

      return (
         <Box sx={{ width: 500, maxWidth: '100%' }}>
            {/* 제목 */}
            {props.mapId ? (
               <WSubTitle>{lang.w.월드정보}</WSubTitle>
            ) : (
               <WSubTitle>{lang.w.새월드만들기}</WSubTitle>
            )}
            <Box p={1}>
               <ValidatedTextField
                  label={lang.common.제목}
                  fullWidth
                  validate
                  showError={showError}
                  min={1}
                  max={22}
                  value={inputValues.title.value}
                  onChange={(e) => {
                     let value = e.target.value;
                     if (value !== inputValues.title.value) {
                        inputValues.title.value = value;
                        setRefresh(!refresh);
                     }
                  }}
                  onValidate={(error) => {
                     inputValues.title.error = error;
                  }}
               />
            </Box>
            <Box p={1}>
               <ValidatedTextField
                  label={lang.common.설명}
                  fullWidth
                  validate
                  showError={showError}
                  min={1}
                  max={100}
                  value={inputValues.desc.value}
                  onChange={(e) => {
                     let value = e.target.value;
                     if (value !== inputValues.desc.value) {
                        //중복된 개행 문자 제거
                        inputValues.desc.value = value.replace('\n\n', '\n');
                        setRefresh(!refresh);
                     }
                  }}
                  onValidate={(error) => {
                     inputValues.desc.error = error;
                  }}
                  multiline
               />
            </Box>
            <Box p={0} sx={{ textAlign: 'left' }}>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.w.맵종류}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        // disabled
                        value={inputValues.map.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.map.value = e.target.value;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="mapBlock001">{lang.w.맵블록001}</MenuItem>
                        <MenuItem value="mapEmpty001">{lang.w.맵빈공간001}</MenuItem>
                        <MenuItem sx={{ display: 'none' }} value="mapGallery001">
                           mapGallery001
                        </MenuItem>
                     </Select>
                  </Box>
               </Box>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.w.맵크기}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        value={inputValues.size.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.size.value = e.target.value;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="size50">50</MenuItem>
                        <MenuItem value="size100">100</MenuItem>
                        <MenuItem value="size200">200</MenuItem>
                     </Select>
                  </Box>
               </Box>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.common.카테고리}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        value={inputValues.category.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.category.value = e.target.value;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="etc">{lang.common.etc}</MenuItem>
                        <MenuItem value="exhibition">{lang.common.exhibition}</MenuItem>
                        <MenuItem value="event">{lang.common.event}</MenuItem>
                        <MenuItem value="homepage">{lang.common.homepage}</MenuItem>
                        {/* <MenuItem value="game">{lang.common.game}</MenuItem> */}
                     </Select>
                  </Box>
               </Box>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.common.공개여부}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        // disabled
                        value={inputValues.visibility.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.visibility.value = e.target.value;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="private">{lang.common.비공개}</MenuItem>
                        <MenuItem value="friends">{lang.common.친구만공개}</MenuItem>
                        <MenuItem value="public">{lang.common.공개}</MenuItem>
                     </Select>
                  </Box>
               </Box>
            </Box>
            <Box p={0} sx={{ textAlign: 'right' }}>
               {props.mapId && (
                  <CButton
                     sx={{ fontSize: '1em', m: 0.5 }}
                     color={'secondary'}
                     onClick={async () => {
                        let ret = await MsgBoxM.Open(
                           lang.common.정말삭제하시겠습니까,
                           lang.common.ok,
                           lang.common.cancel,
                        );
                        if (ret.btn === lang.common.ok) {
                           await WRepository.DeleteMap(props.mapId!);
                           ToastM.Open(lang.common.삭제되었습니다);
                           MySystem.ForceUpdate();
                           ModalM.Close();
                        }
                     }}
                  >
                     <Delete color="error" sx={{ fontSize: '1.9em' }} />
                  </CButton>
               )}
               <CButton
                  color={isDataChanged ? 'primary' : 'secondary'}
                  sx={{ fontSize: '1.1em', m: 0.5 }}
                  // startIcon={<Check />}
                  onClick={Submit}
               >
                  {props.mapId ? (isDataChanged ? lang.common.저장 : lang.common.ok) : lang.common.만들기}
               </CButton>
               {props.mapId && (
                  <CButton
                     sx={{ fontSize: '1.1em', m: 0.5 }}
                     startIcon={<PlayArrow />}
                     onClick={async () => {
                        await Submit();
                        if (props.cbEditWorld) props.cbEditWorld();
                        else WSystem.OpenMap(props.mapId!, 'edit');
                     }}
                  >
                     {lang.w.편집모드}
                  </CButton>
               )}
            </Box>
         </Box>
      );
   },
);
