import { MyStringUtil } from '../base/MyStringUtil';
import { UIAction } from '../reducer/UIReducer';

/**
 * 콘솔 출력 관리자
 * @author elee
 * @date 2021-04-13,
 */
export class MyConsoleManager {
   static line: number = -1;

   static Log(log: any, id?: number): number {
      if (!log) return -1; // 텍스트가 없으면 무시
      log = MyStringUtil.StringVal(log);

      //id === 0도 처리해야 하므로 명확하게 비교
      let line = -1;
      //로그 추가
      if (!log.startsWith('Debug: ')) {
         if (id === undefined) line = ++this.line;
         else line = id; //patch line
         log = line + ': ' + log;
      }

      //id === 0도 처리해야 하므로 명확하게 비교
      if (id === undefined) UIAction.AddLog(log);
      else UIAction.PatchLog(log);

      //최하단으로 스크롤
      const elem = document.getElementById('console');
      if (elem) {
         setTimeout(() => {
            elem.scrollTop = elem.scrollHeight;
         }, 100);
      }

      return line;
   }

   static LogClear() {
      this.line = -1;
      UIAction.ClearLog();
   }
}
