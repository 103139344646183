import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { SMKeyValueTable } from '../../common/components/SMKeyValueTable';
import { config } from '../../common/config/config';
import { lang } from '../../common/config/lang';
import { MySystem } from '../../common/util/MySystem';
import { WPageCreateRoom } from '../page/WPageCreateRoom';
import { WImageViwerData, WPageImageViewer } from '../page/WPageImageViewer';
import { WPageUserInfo } from '../page/WPageUserInfo';
import { WMapInfoData, WRepository, WUser } from './WRepository';

export class WSystem {
   static OpenMap(mapId: string, mode: 'edit' | 'play' = 'play') {
      if (mode === 'edit' && !WRepository.user) {
         MsgBoxM.Open(lang.common.로그인이필요합니다, lang.common.ok);
         return;
      }
      // let home = window.location.href;
      // let session = WAccount.s.user.access;
      MySystem.OpenUrl(
         `/worldmaker/loader.html?mode=${mode}&room=${encodeURIComponent(mapId!)}&home=${encodeURIComponent(
            `https://${window.location.host}`,
         )}`,
      );
   }
   /** (디버깅) 모든 데이터 보여주기 */
   static ShowAllVariables() {
      const data = {
         config: config,
         account: WRepository.user,
      };
      MsgBoxM.Open(<SMKeyValueTable data={data} />);
   }

   // 마이월드메이커(with 갤러리메이커) 사용료 정책 (임시)

   // basic: 무료, 워터마크 o, 동접 15명,  5개 월드 생성 가능.
   // pro: 1000만원(365일 사용권), 워터마크 x, 동접 100명,  5개 월드 생성 가능.
   // enterprise: 2000만원(365일 사용권), 워터마크 x, 동접 200명,  5개 월드 생성 가능.
   // 월드 제작 대행: 월드 당 300만원.

   // * 정식 런칭(22년 12월 예정) 전 년간 유료 회원 등록시, 정식 런칭까지 무료 사용 가능 (사용권 차감 안됨).
   // * 사용권 모두 소진시 basic 회원으로 자동 전환.
   // * 사용 안하는 월드는 보관함에 보관 가능.
   static #GetMaxMapCount(user: WUser): number {
      switch (user.authority) {
         case 'pro':
            return 10;
         case 'enterprise':
            return 10;
         case 'manager':
            return 200;
         case 'admin':
            return 1000;
         case 'basic':
         default:
            return 5;
      }
   }

   static async OpenRoomCreateWindow(map?: WMapInfoData, copyMapId?: string, category?: string) {
      if (await WRepository.CheckLogin()) {
         if (WRepository.mapList.length >= this.#GetMaxMapCount(WRepository.user!)) {
            MsgBoxM.Open(lang.w.내월드목록제한수초과, lang.common.ok);
            return;
         }
         await ModalM.Open(<WPageCreateRoom map={map} copyMapId={copyMapId} category={category} />);
      }
   }

   static async OpenRoomInfoWindow(mapId: string, map: WMapInfoData, cbEditWorld?: () => void) {
      if (await WRepository.CheckLogin()) {
         await ModalM.Open(<WPageCreateRoom mapId={mapId} map={map} cbEditWorld={cbEditWorld} />);
      }
   }

   static async OpenUserInfoWindow(userData: WUser) {
      await ModalM.Open(<WPageUserInfo userData={userData} />);
   }

   static async OpenUserInfoWindowById(uid: string) {
      let result = await WRepository.GetUserInfo(uid);
      if (result) {
         await ModalM.Open(<WPageUserInfo userData={result.data as WUser} />);
      }
   }

   static async OpenImageViewer(data: WImageViwerData) {
      let ret = await ModalM.Open(
         <WPageImageViewer data={data} />,
         undefined,
         undefined,
         undefined,
         undefined,
         undefined,
         true,
      );
      // if (ret.btn === lang.common.더보기) {
      //    data.link && MySystem.OpenUrl(data.link);
      // }
   }
}
