import { Backdrop, Box, CircularProgress, useTheme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MyTaskM, TaskData } from '../manager/MyTaskM';
import { RootStateType } from '../reducer/RootReducer';
import { MyDebug } from '../util/MyDebug';

/** 스타일 정의 */
// let useStyles = makeStyles((theme: Theme) => ({
//    loading: {
//       margin: 5,
//       width: 15,
//    },
// }));

/**
 * console at the bottom
 * @author elee
 * @date 2021-03-31,
 */
export const CConsole = memo(({
   showTasks,
   useConsole,
   showStatusBar,
   defaultOpen,
   windowHeight = 100,
   statusBarHeight = 0,
}: {
   showTasks?: boolean;
   color?: any;
   useConsole?: boolean;
   showStatusBar?: boolean;
   defaultOpen?: boolean;
   windowHeight?: number;
   statusBarHeight?: number;
}) => {
   //상태바 높이 정함
   const theme = useTheme();
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   const [_consoleOpen, setConsoleOpen] = useState(defaultOpen);
   const consoleOpen = _consoleOpen || MyDebug.IsDebug();

   const statusLogs = useSelector((state: RootStateType) => state.ui.statusBarLogs);
   // const status = useSelector((state: RootStateType) => state.ui.statusBarStatus);
   const taskCount = useSelector((state: RootStateType) => state.ui.taskCount);
   // let projectName = useSelector((state: RootStateType) => state.ui.projectName);

   useEffect(() => {
      // 스크롤 다운
      let consoleEl = document.getElementById('CConsole.console');
      if (consoleEl) {
         consoleEl.scrollTop = consoleEl.scrollHeight;
      }
   }, [consoleOpen, statusLogs]);

   /** 핸들러: 서랍 토글 */
   function HandleStatusBarToggle() {
      setConsoleOpen(!consoleOpen);
   }

   // const backgroundBlock = taskCount ? <div style={{ position:'fixed', backgroundColor: 'rgba(0,0,0,0.2)', left:0, top:0, width:'100%', height:'100vh' }}/> : undefined;
   /** 블록 렌더 */
   const BackgroundBlock = (
      <Backdrop
         sx={{
            backgroundColor: 'rgba(0,0,0,0)',
            // zIndex: 10000,
         }}
         open={taskCount > 0}
      />
   );

   /** 태스크(로딩) 렌더 */
   function RenderTask() {
      if (MyTaskM.Empty()) return null;
      // 태스크 리스트 로딩바
      return (
         <Box
            sx={{
               position: 'fixed',
               right: 5,
               zIndex: 10000,
               bottom: (useConsole && consoleOpen) ? windowHeight + statusBarHeight : statusBarHeight,
            }}
         >
            {showTasks ? (
               MyTaskM.GetTasks().map((task, idx) => RenderTaskItem(idx, task))
            ) : (
               <CircularProgress color={'secondary'} size={50} />
            )}
         </Box>
      );
   }

   const RenderTaskItem = (key: number, task: TaskData) => (
      <Box
         key={key}
         sx={{
            margin: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
         }}
      >
         <Box
            sx={{
               color: 'white',
            }}
         >{`${task.msg ?? task.handle}`}</Box>
         <CircularProgress sx={{ color: 'white', marginLeft: 1 }} size={12} />
      </Box>
   );

   // !콘솔 그리기
   if (useConsole) {
      // 상태창에 보여줄 로그 선별. debug: 로 시작하지 않는 마지막 요소 찾아내기
      // const logStatus = statusLogs.reverse().find()
      // let logStatus = '';
      // for (let index = statusLogs.length - 1; index >= 0; index--) {
      //    if (!statusLogs[index].startsWith('debug: ')) {
      //       logStatus = statusLogs[index];
      //       break;
      //    }
      // }
      return (
         <>
            <Box
               height={statusBarHeight + (consoleOpen ? windowHeight : 0)}
               sx={{
                  // position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100vw',
               }}
            >
               {/* 상태바 그리기 */}
               <Box
                  sx={{
                     backgroundColor: theme.palette.primary.main,
                     color: theme.palette.text.primary,
                     width: '100%',
                     height: statusBarHeight,
                  }}
                  onClick={HandleStatusBarToggle}
               >
                  {showStatusBar ? (
                     <>
                        {/*마지막 로그 그리기*/}
                        {/* <div className={classes.statusBarL}>{logStatus}</div> */}
                        {/*프로젝트 이름 그리기*/}
                        {/* <div className={classes.statusBarR}>{`${projectName}`}</div> */}
                        {/*상태? (노드 개수) 그리기*/}
                        {/* <div className={classes.statusBarR}>{status}</div> */}
                        {/*로딩바 그리기*/}
                        {/*<div className={classes.loading}>{taskCount > 0 ? <CircularProgress color="secondary" size={12} /> : null}</div>*/}
                     </>
                  ) : null}
                  {/*태스크바 그리기*/}
                  {RenderTask()}
               </Box>

               {/* 콘솔 그리기 MyConsoleManager에서 console을 사용한다.*/}
               {consoleOpen ? (
                  <Box
                     id={'CConsole.console'}
                     sx={{
                        marginTop: 0, //스테이터스바 높이 만큼 내려서 시작한다
                        textAlign: 'left',
                        paddingLeft: 1,
                        color: theme.palette.grey['400'],
                        flex: 1,
                        height: windowHeight,
                        backgroundColor: theme.palette.grey['900'],
                        // 스크롤
                        overflow: 'auto',
                        fontSize: 10,
                     }}
                  >
                     {statusLogs.map((log, idx) => {
                        if (log.startsWith('Debug: '))
                           return MyDebug.IsDebug() ? (
                              <Box key={idx} sx={{
                                 color: theme.palette.grey['700'],
                              }}>
                                 {log.substring(7)}
                              </Box>
                           ) : null;
                        else
                           return (
                              <Box key={idx}>
                                 {log}
                              </Box>
                           );
                     })}
                  </Box>
               ) : null}
            </Box>
            {BackgroundBlock}
         </>
      );
   } else {
      return (
         <>
            {/*태스크바 그리기*/}
            {RenderTask()}
            {BackgroundBlock}
         </>
      );
   }
});
