import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { lang } from '../config/lang';

/** 키 밸류를 표현하는 간단한 테이블 */
export function SMKeyValueTable(props: { title?: string; style?: CSSProperties; data: any; index?: any }) {
   if (!props.data) {
      return <div>{lang.common.표시할내용이없습니다}</div>;
   } else {
      return (
         <>
            {props.title ? <Typography variant={'h6'}>{props.title}</Typography> : null}
            <TableContainer>
               <Table style={props.style} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell align="left">Key</TableCell>
                        <TableCell align="right">Value</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {Object.keys(props.data).map((key) => (
                        <TableRow key={key}>
                           <TableCell align="left">{key}</TableCell>
                           <TableCell align="right">
                              <div style={{ maxWidth: 500, wordWrap: 'break-word' }}>
                                 {typeof props.data[key] === 'string' ||
                                 typeof props.data[key] === 'number' ? (
                                    <div>{props.data[key]}</div>
                                 ) : (
                                    <Typography style={{ textAlign: 'left', whiteSpace: 'pre' }}>
                                       {JSON.stringify(props.data[key], null, 4)}
                                    </Typography>
                                    // <SMKeyValueTable data={props.data[key][0]} />
                                 )}
                              </div>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </>
      );
   }
}
