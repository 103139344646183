import 'core-js/stable';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import 'regenerator-runtime/runtime';
import { reduxStore } from './common/reducer/reduxStore';
import { RootStateType } from './common/reducer/RootReducer';
import { MyDebug } from './common/util/MyDebug';
import { PMain } from './p/page/PMain';
import { test } from './test';
import { wconfig } from './w/config/wconfig';
import { WMain } from './w/page/WMain';

const Index = () => {
   // useSelector((state: RootStateType) => state.ui.language);
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   return (
      // <Router history={history}>
      //   {/* <Switch> */}
      //     <Route exact path='/' component={MtRoot} />
      //     {/*<Route path="/admin" component={Admin} />*/}
      //     {/*<Route path="/rtl" component={RTL} />*/}
      //     {/*<Route path="/ad" component={ADMenuRoot} />*/}
      //     {/* <Route path='/ml' component={MtRoot} /> */}
      //     {/*다중 패스로 진입 가능하게 해야 한다. startsWith 사용?*/}
      //     {/*<Route component={Root} />*/}
      //   {/* </Switch> */}
      // </Router>
      <div style={{ width: '100vw', minWidth: 0 }}>{wconfig.env.plusdata ? <PMain /> : <WMain />}</div>
   );
};

const container: any = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
   <Provider store={reduxStore}>
      {/* <ErrorBoundary> */}
      <Index />
      {/* </ErrorBoundary> */}
   </Provider>,
);

if (MyDebug.IsDebug()) test();
