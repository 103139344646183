import { MyStringUtil } from '../../common/base/MyStringUtil';
import { config } from '../../common/config/config';

export const wconfig = {
   ui: {
      페이지최고넓이: 900,
      프레임최고넓이: 840,
      이미지최고넓이: 800,      
      이미지최고높이: 700,      
      버튼넓이: 200,
   },
   env: {
      mwm: false,
      dev: false,
      plusdata: false,
   },
   system: {
      월드목록제한수: 2,
   },
   server: {
      url_remote_config: `${MyStringUtil.GetHostURL()}/remote_config.json`,
   },
};

wconfig.env.mwm = config.TITLE === '마이월드메이커' || config.TITLE === '마이월드메이커개발';
wconfig.env.dev = config.TITLE === '마이월드메이커개발';
wconfig.env.plusdata = config.TITLE === '플러스데이타';
