import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { RootStateType } from '../../common/reducer/RootReducer';
import { PPageAbout } from './PPageAbout';

export const WPageBody = memo((props: { category: string }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   return (
      <Box p={1} {...props}>
         {props.category === 'medical' && (
            <Box pb={2}>
               <iframe
                  title={'uyMy5GFvaSTJP6zO3k0x'}
                  frameBorder={0}
                  width="100%"
                  height={500}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                  allowFullScreen
                  src={`https://myworldmaker.com/embed/uyMy5GFvaSTJP6zO3k0x`}
                  style={{ maxWidth: '100%', borderRadius: 5 }}
               ></iframe>
            </Box>
         )}
         {props.category === 'gallery' && (
            <Box pb={2}>
               <iframe
                  title={'gu12SYpxCgrLUm7yUJNA'}
                  frameBorder={0}
                  width="100%"
                  height={500}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                  allowFullScreen
                  src={`https://myworldmaker.com/embed/gu12SYpxCgrLUm7yUJNA`}
                  style={{ maxWidth: '100%', borderRadius: 5 }}
               ></iframe>
            </Box>
         )}
         {props.category === 'about' && (
            <Box pb={2}>
               <PPageAbout />
            </Box>
         )}
         {/* 제목 */}
         {/* {WRepository.user && (
            <WSubTitle>
               <Stack direction={'row'} justifyContent={'center'} spacing={1} alignItems={'center'}>
                  <img width={30} height={30} src={'./globe.png'} alt="" />
                  <Box>{lang.w.추천월드목록}</Box>
                  <CIconButton
                     onClick={async () => {
                        WRepository.LoadMapList();
                        MySystem.ForceUpdate();
                     }}
                  >
                     {!MyTaskM.Empty() ? <CircularProgress size={20} /> : <Refresh />}
                  </CIconButton>
               </Stack>
            </WSubTitle>
         )} */}
         {/* <Box pb={2}>
            <CButton
               startIcon={<Add />}
               color={'secondary'}
               onClick={() => {
                  WSystem.OpenRoomCreateWindow(undefined, undefined, props.category);
               }}
            >
               {props.category === 'my' || props.category === 'all' || props.category === 'etc'
                  ? lang.w.새월드만들기
                  : MyStringUtil.Format(lang.w.새XXX만들기, langM.GetLocalizedText('common', props.category))}
            </CButton>
         </Box> */}
         {/* <WMapList mapList={WRepository.mapList} /> */}
      </Box>
   );
});
