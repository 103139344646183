import { TextField, TextFieldProps } from '@mui/material';
import React, { memo } from 'react';
import { lang } from '../../config/lang';

type TextFieldPropsX = {
   min?: number;
   max?: number;
   label?: string;
   validate?: boolean;
   showError?: boolean;
   onValidate?: (error: boolean) => void;
   onClick?: () => void;
   setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
} & TextFieldProps;

/**
 * 검증 가능한 텍스트필드
 * @author elee
 * @date 2021-07-02,
 */
export const ValidatedTextField = memo((prop: TextFieldPropsX) => {
   // const classes = useStyles(prop);
   let { onValidate, validate, showError, ...rest } = prop;

   //에러 체크
   let error = false;
   let help = undefined;
   let val = String(prop.value);

   if (prop.type === 'date') {
      prop = { ...prop, inputProps: { min: prop.min || '', max: prop.max || '' } };
   }

   if (prop.validate) {
      //타입에 따른 에러 체크
      if (prop.type === 'email') {
         if (val.length > 0) {
            let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
            if (val.match(regExp) == null) {
               error = showError = true;
               help = lang.error.wrong_email_format;
            }
         }
      } else if (prop.type === 'password') {
         if (val.length > 0) {
            let pw = val;
            let checkNumber = pw.search(/[0-9]/g);
            let checkEnglish = pw.search(/[a-z]/gi);
            if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,25}$/.test(pw)) {
               error = showError = true;
               help = lang.error.password_format_invalid;
            } else if (checkNumber < 0 || checkEnglish < 0) {
               error = showError = true;
               help = lang.error.mix_numbers_and_letters;
            }
            // else if (/(\w)\1\1\1/.test(pw)) {
            //   error = showError = true;
            //   help = '같은 문자를 4번 이상 사용하실 수 없습니다.';
            // }
         }
      }
      //길이에 따른 에러 체크
      if (!error) {
         let value = String(prop.value).trim();
         if (prop.min === undefined) prop.min = 0;
         if (prop.max === undefined) prop.max = 10000; //큰 값
         //길이 값에 대한 검증
         const len = value.length;
         error = len < prop.min || len > prop.max;
      }

      //길이에 따른 help 텍스트 생성
      if (error && !help) {
         // 에러가 있을때만 help 생성
         help = prop.helperText ? prop.helperText : '';
         if (prop.min !== undefined || prop.max !== undefined) {
            let prefix = '';
            if (prop.min) prefix = `${prop.min}` + help;
            if (prop.max !== prop.min) prefix += `~${prop.max}`;
            prefix += ' ' + lang.common.글자;
            help = prefix + help;
         }
      }

      //콜백
      if (prop.onValidate) prop.onValidate(Boolean(error));
   }

   if (!showError) error = false;
   if (!error) help = undefined;

   return <TextField {...rest} required={!!prop.min} error={error} helperText={help} />;
   // return <TextField {...rest} className={classes.input} variant="filled" error={error} helperText={help} />;
});

// const useStyles = makeStyles({
//   root: {
//     display: 'grid',
//     alignItems: 'stretch',
//     grid: 'auto-flow / 80% 20%',
//     columnGap: 10,
//   },
//   input: {
//     '& input': {
//       padding: (prop: TextFieldPropsX) => (prop.placeholder ? '15px 12px' : 'auto'),
//     },
//     '& > div': {
//       backgroundColor: (props) => props.style?.backgroundColor || 'auto',
//     },
//     '& > div:hover': {
//       backgroundColor: (props) => props.style?.backgroundColor || 'auto',
//     },
//     '& > div.Mui-focused': {
//       backgroundColor: (props) => props.style?.backgroundColor || 'auto',
//     },
//   },
// });
