import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { rootReducer } from './RootReducer';
// import logger from 'redux-logger'

/**
 * 메인 스토어
 * 비동기 액션을 사용하기 위해 redux-thunk 사용
 * @author elee
 * @date 2021-03-29,
 */
export const reduxStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
// const store = createStore(rootReducer, { tests: { cnt: 1 } });
