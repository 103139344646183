import { log } from 'console';
import { MyStringUtil } from '../base/MyStringUtil';
import { MyUtil } from '../base/MyUtil';
import { mmHistory } from '../io/mmHistory';
import { UIAction } from '../reducer/UIReducer';

export class MySystem {
   static ForceUpdate() {
      setTimeout(() => {
         UIAction.ForceUpdate();
      }, 1);
   }

   /** 경로만 변경 */
   static SetPath(path: string) {
      mmHistory.push(path); // .pushState({}, 'ss', path);
      this.ForceUpdate();
   }

   static OpenUrl(url: string, newWindow = false) {
      if (newWindow) window.open(url, '_blank');
      else window.location.href = url;
   }

   /** 웹뷰 인가? */
   static IsWebView() {
      // 아이패드 크롬
      // Mozilla/5.0 (iPad; CPU OS 15_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/101.0.4951.44 Mobile/15E148 Safari/604.1
      // 아이패드 사파리
      // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15
      // 맥북 크롬
      // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36
      // 맥북 웨일
      // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.57 Whale/3.14.133.23 Safari/537.36
      // 맥북 사파리
      // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15
      // 안드로이드 카톡
      // Mozilla/5.0 (Linux; Android 12; SM-N976N Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/100.0.4896.127 Mobile Safari/537.36;KAKAOTALK 2409770
      // 안드로이드 크롬
      // Mozilla/5.0 (Linux; Android 12; SM-N976N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.41 Mobile Safari/537.36
      let userAgent = window.navigator.userAgent.toLowerCase(),
         safari = /safari/.test(userAgent), //안됨
         appleDevice = /iphone|ipod|ipad|mac/.test(userAgent),
         // androidDevice = /android/.test(userAgent),
         kakao = /kakao/.test(userAgent),
         androidwv = userAgent.includes('wv');

      return kakao || androidwv || (appleDevice && !safari);
   }

   /** 릴리즈 모드인가? (미니파이된 빌드인가) */
   private static _isDevelopMode?: boolean;
   static IsDevelopBuild() {
      if (this._isDevelopMode === undefined) this._isDevelopMode = MySystem.name === 'MySystem';
      return this._isDevelopMode;
   }

   /** 현재 시각 */
   static Now() {
      return Date.now();
   }

   /** window.alert */
   static Alert(txt: string) {
      // if(sys.os == )
      window.alert(txt);
   }

   /** 기본 언어 */
   static GetDefaultLanguage() {
      return navigator.language.split('-')[0];
   }

   /** 작은 디바이스인가 */
   static IsSmallDevice() {
      return window.innerWidth < 992;
   }

   static IsExtraSmallDevice() {
      return window.innerWidth < 768;
   }

   static SetCookie(cookie_name: string, value: string, days?: number) {
      let exdate = new Date();
      if (days) exdate.setDate(exdate.getDate() + days);
      let cookie_value = escape(value) + (days ? '' : '; expires=' + exdate.toUTCString());
      document.cookie = cookie_name + '=' + cookie_value;
   }

   static GetCookie(cookie_name: string): string | undefined {
      let x, y;
      let val = document.cookie.split(';');
      for (let i = 0; i < val.length; i++) {
         x = val[i].substr(0, val[i].indexOf('='));
         y = val[i].substr(val[i].indexOf('=') + 1);
         x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
         if (x === cookie_name) {
            return unescape(y); // unescape로 디코딩 후 값 리턴
         }
      }
      return undefined;
   }

   /** 인터넷 익스플로러 버전 가져오기 */
   // static MsIEVersion(): number | undefined {
   //    const ua = window.navigator.userAgent;
   //    const msie = ua.indexOf('MSIE ');
   //    if (msie > 0) {
   //       // If Internet Explorer, return version number
   //       return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));
   //    } // If another browser, return 0
   //    return undefined;
   // }

   /** 인터넷 익스플로러인가? */
   // static IsExplorer() {
   //    return window.navigator.userAgent.indexOf('MSIE') > 0;
   // }

   /** 몇초간 잠들기 비동기 */
   // static async Sleep(t: number = 1000) {
   //    return new Promise((resolve) => setTimeout(resolve, t));
   // }

   private static _isMobile?: boolean = undefined;
   static IsMobile() {
      function _detectMobileDevice(agent: any) {
         const mobileRegex = [/Android/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
         return mobileRegex.some((mobile) => agent.match(mobile));
      }
      if (this._isMobile === undefined) this._isMobile = _detectMobileDevice(window.navigator.userAgent);
      return this._isMobile;
   }

   static async PostMessage(type: string, data: any, target?: Window) {
      //랜덤 아이디로 콜백을 만들어서 등록
      let wait = true;
      let retVal: any = undefined;
      const callbackId = MyStringUtil.UUID();
      postCallbacks[callbackId] = (ret: any) => {
         wait = false;
         retVal = ret;
      };
      target = target || window.parent;
      target.postMessage({ type: type, data: data, callbackId: callbackId }, '*');
      await MyUtil.WaitWhile(() => wait);
      return retVal;
   }

   static Init() {
      window.removeEventListener('message', postMessageHandler);
      window.addEventListener('message', postMessageHandler);
   }
}

let postCallbacks: { [key: string]: (d: any) => void } = {};
function postMessageHandler(e: any) {
   if (e.data && e.data.callbackId) {
      if (postCallbacks[e.data.callbackId]) {
         console.log('postMessageHandler', e.data.callbackId);
         postCallbacks[e.data.callbackId](e.data.data);
         delete postCallbacks[e.data.callbackId];
      }
   }
}
