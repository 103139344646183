import { Report } from '@mui/icons-material';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import { lang } from '../../common/config/lang';
import { MyTaskM } from '../../common/manager/MyTaskM';
import { WMapInfoData } from '../manager/WRepository';
import { WArticle } from './WArticle';

export const WMapList = (props: { mapList: { uid: string; data: DocumentData }[] }) => {
   // const [previewId, setPreviewId] = useState('');
   // React.useEffect(() => {
   //    (props.mapList && props.mapList.length > 0) && setPreviewId(props.mapList[0].uid);
   // }, [props.mapList]);

   return (
      <>
         <Stack direction="column" spacing={1.5}>
            {props.mapList && props.mapList.length > 0 ? (
               props.mapList.map((map, idx) => {
                  return (
                     <WArticle
                        key={map.uid}
                        mapId={map.uid}
                        preview={idx === 0}
                        map={map.data as WMapInfoData}
                     />
                  );
               })
            ) : (
               <>
                  <Stack
                     direction={'row'}
                     pt={3}
                     justifyContent={'center'}
                     alignItems={'center'}
                     spacing={0.5}
                  >
                     {MyTaskM.Empty() ? (
                        <>
                           <Report color={'error'} />
                           <Typography color={'error'}>{lang.common.표시할내용이없습니다}</Typography>
                        </>
                     ) : (
                        <CircularProgress size={20} />
                     )}
                  </Stack>
               </>
            )}
         </Stack>
      </>
   );
};
