import { Box, BoxProps } from '@mui/material';
import { memo } from 'react';

export const WTitle = memo((props: BoxProps) => {
   return <Box className={props.className ?? 'title'} {...props} />;
});

export const WSubTitle = memo((props: BoxProps) => {
   return <Box className={props.className ?? 'title'} sx={{ fontSize: '1.4em', p: 1 }} {...props} />;
});
