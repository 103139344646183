import { Check } from '@mui/icons-material';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CButton } from '../../common/components/CButton';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { ToastM } from '../../common/components/toast/ToastM';
import { ValidatedTextField } from '../../common/components/validate/ValidatedTextField';
import { lang } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { WAvatar } from '../components/WAvatar';
import { WSubTitle } from '../components/WTitle';
import { WRepository, WUser } from '../manager/WRepository';

// let inputValuesCheck: any = {};
let inputValues = resetValues();
function resetValues() {
   return {
      photoURL: {
         value: '',
         error: false,
         value0: '',
      },
      displayName: {
         value: '',
         error: false,
         value0: '',
      },
      uid: {
         value: '',
         error: false,
         value0: '',
      },
      email: {
         value: '',
         error: false,
         value0: '',
      },
      emailVisibility: {
         value: 'public',
         error: false,
         value0: '',
      },
      desc: {
         value: '',
         error: false,
         value0: '',
      },
      authority: {
         value: 'basic',
         error: false,
         value0: '',
      },
   };
}

export const WPageUserInfo = memo((props: { userData: WUser }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   const [refresh, setRefresh] = useState(false);
   const [showError, setShowError] = useState(false);
   const isMyUserInfo = props.userData.uid === WRepository.user?.uid;
   const isDataChanged = !!GetSaveData();
   // let guide = props.guide;
   // if (isDataChanged) guide = '정보가 변경되었습니다.';

   useEffect(() => {
      console.log('WPageUserInfo.init');
      inputValues = resetValues();
      if (props.userData) {
         inputValues.photoURL.value0 = inputValues.photoURL.value = props.userData.photoURL ?? '';
         inputValues.displayName.value0 = inputValues.displayName.value = props.userData.displayName ?? '';
         inputValues.uid.value0 = inputValues.uid.value = props.userData.uid ?? '';
         inputValues.email.value0 = inputValues.email.value = props.userData.email ?? '';
         inputValues.desc.value0 = inputValues.desc.value = props.userData.desc ?? '';
         if (props.userData.emailVisibility)
            inputValues.emailVisibility.value0 = inputValues.emailVisibility.value =
               props.userData.emailVisibility;
         if (props.userData.authority)
            inputValues.authority.value0 = inputValues.authority.value = props.userData.authority;
      }
      // inputValuesCheck = inputValues.displayName.value + inputValues.desc.value;
      setRefresh(!refresh);
   }, []);

   function GetSaveData() {
      let saveData: any = null;
      for (const key in inputValues) {
         let v = (inputValues as any)[key];
         if (v.value !== v.value0) {
            if (saveData === null) saveData = {};
            saveData[key] = v.value;
         }
      }
      return saveData;
   }

   async function Submit() {
      if (isMyUserInfo && isDataChanged) {
         setShowError(true);

         // 입력값 검증
         for (const key in inputValues) {
            if ((inputValues as any)[key].error) {
               ToastM.Open(lang.common.입력값을확인하세요, 'error');
               return;
            }
         }

         // 값 변화된 값만 저장
         let saveData = GetSaveData();
         if (saveData) {
            await WRepository.SaveMyInfo(saveData);
         }
      }

      // 창 닫기
      ModalM.Close();
   }

   return (
      <Box sx={{ width: 500, maxWidth: '100%' }}>
         {/* 제목 */}
         <WSubTitle>{lang.common.유저정보}</WSubTitle>
         {/* {guide && <Box p={0.5} color={red[400]}>{guide}</Box>} */}
         <Box p={2}>
            <WAvatar photo={props.userData.photoURL ?? undefined}>
               <>
                  <Typography fontWeight="bold">{inputValues.displayName.value}</Typography>
                  <Box
                     display="inline-block"
                     m={0.5}
                     p={0.5}
                     pt={0.1}
                     pb={0.1}
                     borderRadius={5}
                     bgcolor={blue[200]}
                     fontSize={'0.6em'}
                  >
                     {inputValues.authority.value}
                  </Box>
               </>
            </WAvatar>
         </Box>
         {isMyUserInfo && (
            <Box p={1}>
               <ValidatedTextField
                  label={lang.common.아이디}
                  disabled
                  fullWidth
                  value={inputValues.uid.value}
               />
            </Box>
         )}
         {(isMyUserInfo || inputValues.emailVisibility.value === 'public') && (
            <Box p={1}>
               <ValidatedTextField
                  label={lang.common.이메일}
                  disabled
                  fullWidth
                  value={inputValues.email.value}
               />
            </Box>
         )}
         <Box p={1}>
            <ValidatedTextField
               label={lang.common.닉네임}
               disabled={!isMyUserInfo}
               fullWidth
               validate
               showError={showError}
               min={1}
               max={15}
               value={inputValues.displayName.value}
               onChange={(e) => {
                  let value = e.target.value;
                  if (value !== inputValues.displayName.value) {
                     inputValues.displayName.value = value;
                     setRefresh(!refresh);
                  }
               }}
               onValidate={(error) => {
                  inputValues.displayName.error = error;
               }}
            />
         </Box>
         <Box p={1}>
            <ValidatedTextField
               label={lang.common.자기소개}
               disabled={!isMyUserInfo}
               fullWidth
               validate
               showError={showError}
               min={0}
               max={200}
               value={inputValues.desc.value}
               onChange={(e) => {
                  let value = e.target.value;
                  if (value !== inputValues.desc.value) {
                     //중복된 개행 문자 제거
                     inputValues.desc.value = value.replace('\n\n', '\n');
                     setRefresh(!refresh);
                  }
               }}
               onValidate={(error) => {
                  inputValues.desc.error = error;
               }}
               multiline
            />
         </Box>
         <Box p={0} sx={{ textAlign: 'right' }}>
            {isMyUserInfo && (
               // <ValidatedCheckbox
               //    label={lang.common.이메일공개}
               //    checked={inputValues.emailVisibility.value}
               //    color="primary"
               //    onChange={(e) => {
               //       inputValues.emailVisibility.value = e.target.checked;
               //       setRefresh(!refresh);
               //    }}
               // />
               <Box p={1} display={'inline-block'}>
                  <Box p={1} display={'inline-block'}>
                     {lang.common.이메일공개}
                  </Box>
                  <Select
                     // disabled
                     id="map"
                     value={inputValues.emailVisibility.value}
                     autoWidth
                     onChange={(e) => {
                        inputValues.emailVisibility.value = e.target.value;
                        setRefresh(!refresh);
                     }}
                  >
                     <MenuItem value="private">{lang.common.비공개}</MenuItem>
                     <MenuItem value="friends">{lang.common.친구만공개}</MenuItem>
                     <MenuItem value="public">{lang.common.공개}</MenuItem>
                  </Select>
               </Box>
            )}
            <CButton
               color={isMyUserInfo && isDataChanged ? 'primary' : 'inherit'}
               sx={{ fontSize: '1.1em', m: 0.5 }}
               startIcon={<Check />}
               onClick={Submit}
            >
               {isMyUserInfo && isDataChanged ? lang.common.저장 : lang.common.ok}
            </CButton>
         </Box>
      </Box>
   );
});
