import { Avatar, AvatarProps, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { MyStringUtil } from '../../common/base/MyStringUtil';

type WAvatarProps = AvatarProps & {
   children?: any;
   photo?: string;
   onClick?: () => void;
};

export const WAvatar = memo((props: WAvatarProps) => {
   return (
      <Stack direction={'row'} flex={1} alignItems="center">
         {/* 아바타 */}
         <Avatar
            src={props.photo}
            onClick={props.onClick}
            sx={{
               ":hover": props.onClick ? {
                  transform: 'scale(1.1)'
               } : undefined,
               cursor: props.onClick ? 'pointer' : undefined,
               width: 30,
               height: 30,
               mr: 0.5,
               bgcolor: props.children && MyStringUtil.StringToColor(props.children),
               ...props.sx,
            }}
         />
         {/* 이름 */}
         {props.children}
      </Stack>
   );
});
