import { MyStorage } from '../api/MyStorage';
import { MySystem } from '../util/MySystem';

class LanguageM {
   ko = {
      _name: '한국어',
      common: {
         더보기: '더 보기',
         카테고리: '카테고리',
         사업문의: '사업문의',
         회사소개: '회사소개',
         ok: '확인',
         cancel: '취소',
         아이디: '아이디',
         예: '예',
         아니오: '아니오',
         닫기: '닫기',
         다시시작: '다시 시작',
         재접속: '재접속',
         재시도: '재시도',
         연결: '접속',
         동기화: '동기화',
         삭제되었습니다: '삭제 되었습니다',
         연결이끊어졌습니다: '연결이 끊어졌습니다.',
         로그아웃하시겠습니까: '로그아웃 하시겠습니까?',
         로그아웃되었습니다: '로그아웃 되었습니다.',
         로딩: '로딩',
         맵로딩: '맵 로딩',
         로그인: '로그인',
         로그아웃: '로그아웃',
         가입: '가입',
         초기화: '초기화',
         전체화면_전환: '전체화면 전환',
         설정: '설정',
         언어: '언어',
         여기에입력하세요: '여기에 입력하세요...',
         그래픽: '그래픽',
         높음: '높음',
         중간: '중간',
         낮음: '낮음',
         생성일: '생성일',
         수정일: '수정일',
         표시할내용이없습니다: '표시할 내용이 없습니다.',
         결과: '결과',
         플레이: '플레이',
         편집: '편집',
         삭제: '삭제',
         다운로드: '다운로드',
         이메일: '이메일',
         이메일공개: '이메일 공개',
         패스워드: '패스워드',
         패스워드확인: '패스워드 확인',
         데이타: '데이타',
         속성: '속성',
         만들기: '만들기',
         다음: '다음',
         이전: '이전',
         보내기: '보내기',
         저장: '저장',
         저장되었습니다: '저장 되었습니다.',
         글자: '글자',
         please_check_xxx: '[{0}]을(를) 확인해 주세요.',
         어바웃: '넌 누구?',
         손님: '손님',
         계속: '계속',
         제목: '제목',
         설명: '설명',
         기본웹브라우저로열기: '기본 웹 브라우저로 열기',
         현재브라우저에서는로그인할수없습니다: '현재 브라우저 에서는 로그인을 할 수 없습니다.',
         로그인하지않고계속진행하시겠습니까: '로그인 하지 않고 계속 진행 하시겠습니까?',
         입력값을확인하세요: '입력 값을 확인하세요.',
         사업계획서: '백서',
         로그인이필요합니다: '로그인이 필요합니다.',
         정말삭제하시겠습니까: '정말 삭제 하시겠습니까?',
         유저정보: '유저 정보',
         닉네임: '닉네임',
         추가정보가필요합니다: '추가 정보가 필요합니다.',
         자기소개: '자기 소개',
         비공개: '비공개',
         친구만공개: '친구만 공개',
         공개: '공개',
         공개여부: '공개 여부',
         리다이렉트: '리다이렉트',
         소스코드복사: '소스 코드 복사',
         복제: '복제',
         복사: '복사',
         복사완료: '복사 완료',
         내용없음: '내용 없음',
         all: '전체',
         etc: '기타',
         exhibition: '전시회',
         event: '이벤트',
         homepage: '홈페이지',
         game: '게임',
         medical: '의료',
         gallery: '갤러리',
      },
      w: {
         제목: '마이월드메이커',
         개요: '내 맘대로 만드는 메타버스 세상!',
         주소: '마이월드메이커(주) | 경기 성남시 분당구 판교역로192번길 16, 판교타워 806호 C508',
         추천월드목록: '추천 월드 목록',
         내월드목록: '내 월드 목록',
         새월드만들기: '새 월드 만들기',
         새XXX월드만들기: '새 {0} 월드 만들기',
         월드정보: '월드 정보',
         편집모드: '편집 모드',
         월드복제: '월드 복제',
         어바웃01:
            '마이월드메이커는 별도의 설치 과정 없이 웹브라우저에서 바로 실행되는 메타버스 플랫폼 입니다.',
         어바웃02:
            '세상에 나를 알리자! 이젠 텍스트 기반의 홈페이지 대신, 나만의 3D 메타버스 공간을 만들어 세상에 공개해 보세요!',
         어바웃03:
            '2022년 4분기에 정식 출시 예정으로, 현재는 개발 단계이므로 일부 기능이 작동하지 않을 수 있습니다.',
         어바웃04: '지원 브라우저: 크롬, 사파리, 엣지, 파이어폭스, 웨일, 기타 웹킷 기반 브라우저',
         어바웃05: '', //'문의: castepo@gmail.com / 이창하',
         맵블록001: '블록 001',
         맵빈공간001: '빈 공간',
         xxx월드가생성되었습니다: '{0} 월드가 생성 되었습니다.',
         맵종류: '맵 종류',
         맵크기: '맵 크기',
         내월드목록제한수초과: '내 월드 목록 제한 수가 초과 되었습니다.',
         내월드: '내 월드',
      },
      p: {
         제목: '플러스데이타',
         개요: '플러스데이타는...',
         어바웃01: '플러스데이타는...',
         어바웃02: '플러스데이타는...',
         어바웃03: '플러스데이타는...',
         어바웃04: '플러스데이타는...',
         주소: '플러스데이타(주) | 플러스시 플러스구 플러스로 192번길 16, 플러스타워 806호',
      },
      error: {
         connection_failed: '연결 실패!',
         no_content_to_save: '저장할 내용이 없습니다.',
         value_is_empty: '값이 비어 있습니다.',
         error: '오류',
         fail: '실패',
         wrong_parameters: '입력값이 잘못 되었습니다.',
         password_does_not_match: '패스워드가 일치하지 않습니다.',
         api_error: 'API 오류',
         no_values_changed: '변경된 값이 없습니다.',
         user_not_found: '유저를 찾을 수 없습니다.',
         user_already_exist: '유저가 이미 존재합니다.',
         please_try_later: '잠시 후에 시도해 주세요',
         wrong_email_format: '이메일 형식에 맞지 않습니다.',
         password_format_invalid: '숫자+영문자+특수문자 조합으로 8자리 이상 사용해야 합니다.',
         mix_numbers_and_letters: '숫자와 영문자를 혼용하여야 합니다.',
         input_value_is_not_correct: '입력값이 올바르지 않습니다.',
      },
   };
   en: Lang = {
      _name: 'English',
      common: {
         더보기: 'More',         
         카테고리: 'Category',
         사업문의: 'Contact',
         회사소개: 'About',
         all: 'All',
         etc: 'Etc',
         ok: 'OK',
         cancel: 'Cancel',
         아이디: 'ID',
         예: 'Yes',
         아니오: 'No',
         닫기: 'Close',
         다시시작: 'Restart',
         재접속: 'Reconnect',
         재시도: 'Retry',
         연결: 'Connecting',
         동기화: 'Synchronizing',
         삭제되었습니다: 'Deleted successfully',
         연결이끊어졌습니다: 'Connection was lost.',
         로그아웃하시겠습니까: 'Sign out?',
         로그아웃되었습니다: 'Signed out successfully.',
         로딩: 'Loading',
         맵로딩: 'Map Loading',
         로그인: 'Sign in',
         로그아웃: 'Sign out',
         가입: 'Signup',
         초기화: 'Reset',
         전체화면_전환: 'Fullscreen',
         설정: 'Settings',
         언어: 'Language',
         여기에입력하세요: 'Enter text here...',
         그래픽: 'Graphics',
         높음: 'High',
         중간: 'Medium',
         낮음: 'Low',
         생성일: 'Created at',
         수정일: 'Updated at',
         표시할내용이없습니다: 'No content',
         결과: 'Result',
         플레이: 'Play',
         편집: 'Edit',
         삭제: 'Delete',
         다운로드: 'Download',
         이메일: 'Email',
         이메일공개: 'Email Visibility',
         패스워드: 'Password',
         패스워드확인: 'Password Confirm',
         데이타: 'Data',
         속성: 'Properties',
         만들기: 'Create',
         다음: 'Next',
         이전: 'Prev',
         보내기: 'Send',
         저장: 'Save',
         저장되었습니다: 'Saved successfully.',
         글자: 'Characters',
         please_check_xxx: 'Please check [{0}]',
         어바웃: 'About',
         손님: 'Guest',
         계속: 'Continue',
         제목: 'Title',
         설명: 'Description',
         기본웹브라우저로열기: 'Open in default browser',
         현재브라우저에서는로그인할수없습니다: 'Login is not available in this browser.',
         로그인하지않고계속진행하시겠습니까: 'You are not signed in. Do you want to continue?',
         입력값을확인하세요: 'Please check input value.',
         사업계획서: 'White Paper',
         로그인이필요합니다: 'Sign in required.',
         정말삭제하시겠습니까: 'Do you really want to delete?',
         유저정보: 'User Info',
         닉네임: 'Nickname',
         추가정보가필요합니다: 'Additional information is required.',
         자기소개: 'Introduction',
         비공개: 'Private',
         친구만공개: 'Friends only',
         공개: 'Public',
         공개여부: 'Visibility',
         리다이렉트: 'Redirect',
         소스코드복사: 'Copy Code',
         복제: 'Clone',
         복사: 'Copy',
         복사완료: 'Copied',
         내용없음: 'Empty',
         exhibition: 'Exhibition',
         event: 'Event',
         homepage: 'Homepage',
         game: 'Game',
         medical: 'Medical',
         gallery: 'Gallery',
      },
      w: {
         제목: 'My World Maker',
         주소: 'Copyright 2022. My World Maker. All rights reserved.',
         개요: 'Create My Own Metaverse!',
         추천월드목록: 'Hot World List',
         내월드목록: 'My World List',
         새월드만들기: 'Create New World',
         새XXX월드만들기: 'Create New {0} World',
         월드정보: 'World Info',
         편집모드: 'Edit Mode',
         월드복제: 'Clone World',
         어바웃01:
            'My World Maker is a metaverse platform that runs directly in a web browser without a separate installation process.',
         어바웃02:
            'Let the world know me! Now, instead of a text-based homepage, create your own 3D metaverse space and share it to the world!',
         어바웃03:
            'My World Maker will be launched in Q4 2022. In the current development stage, not all features may work properly.',
         //지원 브라우저: 크롬, 사파리, 파이어폭스, 웹킷 기반 브라우저
         어바웃04: 'Browser Support: Chrome, Safari, Edge, Firefox, Whale, and other Webkit-based browsers.',
         어바웃05: '', //Contact: castepo@gmail.com / Evan Lee',
         맵블록001: 'Block 001',
         맵빈공간001: 'Empty Space',
         xxx월드가생성되었습니다: '{0} world has been created.',
         맵종류: 'Map Type',
         맵크기: 'Map Size',
         내월드목록제한수초과: 'You have exceeded the limit of your world list.',
         내월드: 'My World',
      },
      p: {
         제목: 'Plusdata',
         개요: 'Plusdata is...',
         어바웃01: 'Plusdata is...',
         어바웃02: 'Plusdata is...',
         어바웃03: 'Plusdata is...',
         어바웃04: 'Plusdata is...',
         주소: 'Plusdata Co., Ltd. | #806 Plus Tower, 16 Plus-ro 192beon-gil, Plus-gu, Plus City',
      },
      error: {
         connection_failed: 'Connection failed.',
         no_content_to_save: 'No content to save',
         value_is_empty: 'Value is empty.',
         error: 'Error',
         fail: 'Fail',
         wrong_parameters: 'Wrong parameters.',
         password_does_not_match: 'Password does not match.',
         api_error: 'API error',
         no_values_changed: 'No values changed.',
         user_not_found: 'User not found.',
         user_already_exist: 'User already exists.',
         please_try_later: 'Please try later.',
         wrong_email_format: 'Wrong email format',
         password_format_invalid: '8 characters or more with numbers, letters, and special characters.',
         mix_numbers_and_letters: 'You must mix numbers and letters.',
         input_value_is_not_correct: 'Input value is not correct.',
      },
   };

   GetCurrentLanguage() {
      // let paths = window.location.href.split('/');
      // let path = paths.length > 3 ? paths.pop() : undefined;
      return this.#ToSupportedLanguageCode(
         // path || MyStorage.GetString('lang') || MySystem.GetDefaultLanguage(),
         MyStorage.GetString('lang') || MySystem.GetDefaultLanguage(),
      );
   }

   SetCurrentLanguage(langCode: string) {
      langCode = this.#ToSupportedLanguageCode(langCode);
      MyStorage.SetString('lang', langCode); // 본 앱에서 사용
      // myHistory.push(langCode);
      let langData = (this as any)[langCode];
      // Object.assign(lang, langData);
      lang = langData;
      // 창 제목 변경
      document.title = lang.w.제목;
   }

   GetLocalizedText(category: string, key: string): string {
      let objKey = key.replaceAll(' ', '_').toLowerCase();
      let _lang: any = lang;
      if (_lang[category]) {
         if (_lang[category][objKey]) return _lang[category][objKey];
      }
      return key;
   }

   #GetSupportedLanguages() {
      return Object.keys(this);
   }

   #ToSupportedLanguageCode(langCode: string): string {
      if (!langCode || this.#GetSupportedLanguages().indexOf(langCode) === -1) {
         // 지원 언어에 없으면 영어를 기본으로
         langCode = 'en';
      }
      return langCode;
   }
}

export const langM = new LanguageM();

type Lang = typeof langM.ko;
export let lang: Lang = langM.ko;

langM.SetCurrentLanguage(langM.GetCurrentLanguage());
