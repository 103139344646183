import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { langM } from '../config/lang';
import { UIAction } from '../reducer/UIReducer';

/**
 * 언어 선택 드롭다운
 * @author elee
 * @date 2021-04-27,
 */
export function CLanguageSelect() {
   // const language = useSelector((state: RootStateType) => state.ui.language);

   return (
      <Select
         value={langM.GetCurrentLanguage()}
         sx={{ height: 38 }}
         onChange={(evt) => {
            langM.SetCurrentLanguage(evt.target.value);
            UIAction.ForceUpdate();
         }}
      >
         <MenuItem value="ko">Korean</MenuItem>
         <MenuItem value="en">English</MenuItem>
      </Select>
   );
}
