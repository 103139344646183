// region 상태 ========================================
import { SnackbarProvider, useSnackbar } from 'notistack';
import { memo } from 'react';
import { config } from '../../config/config';
import { ToastM } from './ToastM';

/**
 * 노티피케이션 스택
 * UIManager.Toast 에서 호출
 * @author elee
 * @date 2021-03-12,
 */
export const CNotificationStack = memo(() => {
  return (
    <SnackbarProvider maxSnack={config.toastCntMax} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <NotificationStackChild />
    </SnackbarProvider>
  );
});

const NotificationStackChild = memo(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); //<SnackbarProvider> 가 부모에 있어야 한다.
  //UIManager 에 후크 할당
  ToastM.enqueueSnackbar = enqueueSnackbar;
  ToastM.closeSnackbar = closeSnackbar;
  return <></>;
});

