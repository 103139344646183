import { MyStringUtil } from './common/base/MyStringUtil';

export function test() {
   console.log('==================test start');
   // let b = '/sdfsdf/';
   // console.log(MyStringUtil.Format('a{0}b{1}c', 0));
   // console.log(MyStringUtil.Trim(b, '/'));
   // console.log(MyStringUtil.AppendToken('/bbb///', '/aaa/', '/'));
   console.log('==================test end');
}
