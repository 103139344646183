import { memo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

import { useSelector } from 'react-redux';
import GG from '../../assets/img/GoogleIcon.svg';
import { CButton } from '../../common/components/CButton';
import { CLanguageSelect } from '../../common/components/CLanguageSelect';
import { config } from '../../common/config/config';
import { lang } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { WAvatar } from './WAvatar';

/** 상단 메뉴 */
export const WAppBar = memo((props: { onLogout?: () => void; onLogin?: () => void }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   return (
      <Stack p={1} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
         {WRepository.user ? (
            // eslint-disable-next-line react/jsx-no-undef
            <WAvatar
               photo={WRepository.user?.photoURL ?? undefined}
               onClick={() => {
                  if (WRepository.user) WSystem.OpenUserInfoWindow(WRepository.user);
               }}
            >
               <>
                  <Typography fontWeight="bold" textOverflow="ellipsis">
                     {WRepository.user?.displayName}
                  </Typography>
                  <Box
                     display="inline-block"
                     m={0.5}
                     p={0.5}
                     pt={0.1}
                     pb={0.1}
                     borderRadius={5}
                     bgcolor={blue[200]}
                     fontSize={'0.6em'}
                  >
                     {WRepository.user?.authority}
                  </Box>
               </>
            </WAvatar>
         ) : (
            <Box flex={1}></Box>
         )}
         {/* 로그인 버튼 */}
         {config.USE_LOGIN && (
            <CButton
               sx={{ height: 36 }}
               color={'secondary'}
               startIcon={<img src={GG} alt={GG} />}
               onClick={async () => {
                  if (WRepository.user) {
                     await WRepository.Logout();
                     props.onLogout?.();
                  } else {
                     await WRepository.Login();
                     props.onLogin?.();
                  }
               }}
            >
               <Typography noWrap>{WRepository.user ? lang.common.로그아웃 : lang.common.로그인}</Typography>
            </CButton>
         )}
         <CLanguageSelect />
         {/* 모바일 메뉴 버튼 */}
         {/* {
         isMobile && <IconButton onClick={() => {
            setMenuOpen(!menuOpen);
         }}>
            <Menu fontSize={'large'} />
         </IconButton>
      } */}
      </Stack>
   );
});
