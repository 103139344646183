import { combineReducers } from 'redux';
import { UIReducer } from './UIReducer';
import { MsgBoxReducer } from '../components/msgbox/MsgBoxReducer';
import { ModalReducer } from '../components/msgbox/ModalReducer';

/**
 * 루트 리듀서
 * @author elee
 * @date 2021-03-29,
 */
export const rootReducer = combineReducers({
   ui: UIReducer,
   // modal: MsgBoxReducer,
   msg: MsgBoxReducer,
   modal: ModalReducer,
   // modal: ModalBoxReducer,
});

/** 리듀서 반환 타입 */
export type RootStateType = ReturnType<typeof rootReducer>;
