import { MyStringUtil } from '../base/MyStringUtil';
import { MyDebug } from '../util/MyDebug';

function E(value: string | null) {
   return value ? btoa(escape(value)) : '';
}

function D(value: string | null) {
   return value ? unescape(atob(value)) : null;
}

export class MyStorage {
   static GetString(key: string) {
      return D(window.localStorage.getItem(E(key)));
   }
   static GetNumber(key: string) {
      let strVal = this.GetString(key);
      if (!strVal) return null;
      else return Number(strVal);
   }
   static GetBool(key: string) {
      let strVal = this.GetNumber(key);
      if (!strVal) return null;
      else return strVal === 1;
   }
   static GetObject<T>(key: string, prototype: T) {
      let str = this.GetString(key);
      if (str) {
         let obj = JSON.parse(str);
         obj.__proto__ = prototype;
         return obj as T;
      }
      return null;
   }
   static SetString(key: string, value: string) {
      window.localStorage.setItem(E(key), E(value));
      MyDebug.Debug(`MyStorage.SetString( ${key}, ${MyStringUtil.Shorten(value, 50)} )`);
   }
   static SetNumber(key: string, value: number) {
      this.SetString(key, value.toString());
   }
   static SetBool(key: string, value: boolean) {
      this.SetString(key, value ? '1' : '0');
   }
   static SetObject(key: string, value: any) {
      this.SetString(key, JSON.stringify(value));
   }
}
