export class MyStringUtil {
   static GetHostURL() {
      return window.location.protocol + '//' + window.location.host;
   }
   static StringToColor(string: string) {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
         hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = '#';

      for (i = 0; i < 3; i += 1) {
         const value = (hash >> (i * 8)) & 0xff;
         color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
   }

   /** 스트링 길이 제한 */
   static Shorten(value: string, len: number) {
      len = Math.max(len, 3);
      if (value.length <= len) return value;
      else return value.substring(0, len - 2) + '...';
   }

   static AppendToken(v1: string, v2: string, token: string): string {
      return this.Trim(v1, token) + token + this.Trim(v2, token);
   }

   /** 모든 객체를 스트링값으로 표현 */
   static StringVal(data: any) {
      return typeof data === 'string' ? (data as string) : JSON.stringify(data, null, 4);
   }

   static Camelize(text: string) {
      return text.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2, offset) => {
         if (p2) return p2.toUpperCase();
         return p1.toLowerCase();
      });
   }

   static Decamelize(str: string, separator?: string) {
      separator = separator ?? ' ';
      return str
         .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
         .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
         .toLowerCase();
   }

   /** 첫글자를 대문자로 */
   static Capitalize(input: string) {
      // if (typeof input !== 'string') return '';
      input = input.trim();
      if (input.length === 0) return input;
      else return input.charAt(0).toUpperCase() + input.slice(1);
   }

   static FormatDate(d: Date) {
      let month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
   }

   static FormatDateTime(d: Date) {
      let month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear(),
         hour = '' + d.getHours(),
         minutes = '' + d.getMinutes(),
         second = '' + d.getSeconds();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      if (hour.length < 2) hour = '0' + hour;
      if (minutes.length < 2) minutes = '0' + minutes;
      if (second.length < 2) second = '0' + second;

      return [year, month, day].join('-') + ' ' + [hour, minutes, second].join(':');
   }

   /** yyyy-mm-dd 형식으로 변환 */
   static ToYYYYMMDD(date: string) {
      return this.FormatDate(new Date(date));
   }

   /** yyyy-mm-dd 00:00:00 형식으로 변환 */
   static ToYYYYMMDD000000(date: string) {
      // return new Date(date).toISOString();
      return this.FormatDateTime(new Date(date));
   }

   /** 앞뒤 문자열 잘라낸다 */
   static Trim(body: string, trim: string): string {
      //*todo 트림 기능 보강
      let re = new RegExp(`[^${trim}].+[^${trim}]`).exec(body);
      if (re) return re[0];
      return body;
   }

   /** 스트링 포맷 */
   static Format(...args: any[]) {
      return args[0].replace(/{(\d+)}/g, (match: any, num: any) => {
         num = Number(num) + 1;
         return args[num] ?? match;
      });
   }

   static UTCTime() {
      return new Date().toUTCString();
   }

   static CountChar(body: string, s: string): number {
      let cnt = 0;
      for (let i = 0; i < body.length; ++i) {
         if (body.charAt(i) === s) ++cnt;
      }
      return cnt;
   }

   static GetDateString(date: Date) {
      const year = date.getFullYear();
      const month = ('0' + (1 + date.getMonth())).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return year + '-' + month + '-' + day;
   }

   static GetDateAfter(start: Date, year: number = 0, month: number = 0, date: number = 0) {
      start.setFullYear(start.getFullYear() + year, start.getMonth() + month, start.getDate() + date);
      return start;
   }

   /** 단위를 붙여서 돈을 표현하는 텍스트를 만든다 */
   static GetMoneyString(number: any, currency: string) {
      if (currency === 'KRW') {
         let numberText = Math.ceil(Number(number)).toLocaleString();
         return `${numberText} 원`;
      } else if (currency === 'USD') {
         let numberText = Number(number).toLocaleString();
         return `$ ${numberText}`;
      } else {
         let numberText = Number(number).toLocaleString();
         return numberText;
      }
   }

   static GetDaysFromMillis(timeDifference: number) {
      return 1 + timeDifference / (1000 * 60 * 60 * 24);
   }

   static UUID() {
      return 'axxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, (c) => {
         var r = (Math.random() * 16) | 0,
            v = r;
         return v.toString(16);
      });
   }

   static UUIDv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
         var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
         return v.toString(16);
      });
   }

   // static Add(sep: string, ...params: any[]) {
   //   return params.join(sep);
   // }

   // 2021-01-01 형태로 잘라내기
   static GetDateStringToday() {
      return new Date().toISOString().substring(0, 10);
   }
}
