import { createTheme } from '@mui/material';

export const p_theme = createTheme({
   palette: {
      mode: 'light',
      // background: {paper: 'rgb(22, 33, 44)', default: 'rgb(22, 33, 44)'},
      primary: {main: 'rgba(66,66,66,1)'},
      // primary: {main: 'rgba(1,140,255,0.8)', light: 'rgba(1,140,255,0.6)', dark: 'rgba(1,110,225,1)'},
      // secondary: {main: '#ff9800'},
      // text: {primary: 'rgba(0, 0, 0, 0.75)', secondary: 'rgba(0, 0, 0, 0.55)', disabled: 'rgba(0, 0, 0, 0.38)'},
      // action: {active: 'rgba(0, 0, 0, 0.54)', hover: 'rgba(0, 0, 0, 0.04)', hoverOpacity: 0.04, selected: 'rgba(0, 0, 0, 0.08)', selectedOpacity: 0.08, …},
      // common: {black: '#000', white: '#fff'},
      // contrastThreshold: 3,
      // divider: "rgba(0, 0, 0, 0.12)",
      // error: {main: '#d32f2f', light: '#ef5350', dark: '#c62828', contrastText: '#fff'},
      // grey: {50: '#fafafa', 100: '#f5f5f5', 200: '#eeeeee', 300: '#e0e0e0', 400: '#bdbdbd', 500: '#9e9e9e', 600: '#757575', 700: '#616161', 800: '#424242', 900: '#212121', A100: '#f5f5f5', A200: '#eeeeee', A400: '#bdbdbd', A700: '#616161'},
      // info: {main: '#0288d1', light: '#03a9f4', dark: '#01579b', contrastText: '#fff'},
      // secondary: {main: 'rgba(255, 255, 255, 0.1)', contrastText: '#fff'},//, light: '#ba68c8', dark: '#7b1fa2', contrastText: '#fff'},
      // success: {main: '#2e7d32', light: '#4caf50', dark: '#1b5e20', contrastText: '#fff'},
      // tonalOffset: 0.2,
      // warning: {main: '#ed6c02', light: '#ff9800', dark: '#e65100', contrastText: '#fff'},
   },
   shape: { borderRadius: 25 },
   typography: {
      fontFamily: '"Jua", san-serif',
      // fontFamily: '"Press Start 2P", san-serif',
      fontSize: 12,
      fontWeightBold: 1000,
      fontWeightRegular: 400,
      fontWeightMedium: 400,
      fontWeightLight: 100,
      button: {
         textTransform: 'none', //대문자로 변환 X
      },
   },

   components: {
      MuiButton: {
         styleOverrides: {
            outlined: {
               color: 'red',
            },
         },
      },
   },

   // overrides: {
   //   MuiCssBaseline: {
   //     '@global': {
   //       '*': {
   //         fontFamily: '"SCDream", san-serif',
   //       },
   //       body: {
   //         fontFamily: '"SCDream", san-serif',
   //         backgroundColor: '#F8F9FB',
   //         fontSize: 14,
   //       },
   //       a: {
   //         textDecoration: 'none',
   //         color: 'inherit',
   //       },
   //       '.anchor-label': {
   //         '&:hover': {
   //           opacity: 0.8,
   //         },
   //       },
   //       'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
   //         transition: 'background-color 5000s ease-in-out 0s',
   //       },
   //       'input::placeholder, input::-ms-input-placeholder, input::-webkit-input-placeholder': {
   //         color: 'red',
   //       },
   //       hr: {
   //         borderColor: '#C7CCD150',
   //       },
   //     },
   //   },
   //   MuiButton: {
   //     contained: {
   //       boxShadow: 'unset',
   //       color: '#fff',
   //       backgroundColor: '#868A98',
   //       '&:hover': {
   //         backgroundColor: '#676C81',
   //       },
   //     },
   //   },
   //   MuiInput: {
   //     underline: {
   //       '&:hover:not(.Mui-disabled):before': {
   //         borderBottom: 0,
   //       },
   //       '&:before': {
   //         borderBottom: 0,
   //       },
   //       '&:after': {
   //         borderBottom: 0,
   //       },
   //       borderBottom: 0,
   //     },
   //   },
   //   MuiInputBase: {
   //     root: {
   //       '&$disabled': {
   //         color: 'inherit',
   //       },
   //       backgroundColor: '#F4F6F8',
   //     },
   //     /* placeholder color */
   //     input: {
   //       color: 'inherit',
   //     },
   //   },
   //   MuiFilledInput: {
   //     /* input customize */
   //     root: {
   //       '&$disabled': {
   //         backgroundColor: '#F4F6F8',
   //       },
   //       backgroundColor: '#F4F6F8',
   //       '&:hover': {
   //         backgroundColor: '#F4F6F8',
   //       },
   //       '&.Mui-focused': {
   //         backgroundColor: '#F4F6F8',
   //       },
   //       borderRadius: 5,
   //     },
   //     /* delete underline */
   //     underline: {
   //       '&:hover:before': {
   //         borderBottom: 0,
   //       },
   //       '&:before': {
   //         borderBottom: 0,
   //       },
   //       '&:after': {
   //         borderBottom: 0,
   //       },
   //       borderBottom: 0,
   //     },
   //     multiline: {
   //       padding: '15px 12px',
   //     },
   //   },
   // },
});
