import { MySystem } from "../util/MySystem";

//어디에서나 접근 가능하애 하므로 import 가 없게 유지한다.
export const config = {
   _debug: false, //디버그 메시지 표시 여부
   toastCntMax: 10, //화면에 표시되는 최대 토스트 개수
   NOTIFICATION_DELAY: 3500, //토스트 보이는 시간
   TITLE: '',
   URL_API_SERVER: '',
   URL_OPEN_REDIRECTION: 'https://openinapp.co/myworldmaker',
   USE_LOGIN: false,

   get DEBUG() {
      return this._debug;
   },

   set DEBUG(value) {
      this._debug = value;
      //디버그 모드일때 컴포넌트에 줄 그어 보여주기
      if (this._debug) {
         var sheet = document.createElement('style');
         sheet.id = 'styleDebug';
         sheet.innerHTML = '* {box-shadow: 0 0 0 1px rgba(0,120,255,0.2) inset}';
         document.body.appendChild(sheet);
      } else {
         var sheetToBeRemoved = document.getElementById('styleDebug');
         if (sheetToBeRemoved) {
            var sheetParent = sheetToBeRemoved.parentNode;
            sheetParent?.removeChild(sheetToBeRemoved);
         }
      }
   },
};

if (process.env.REACT_APP_TITLE) config.TITLE = process.env.REACT_APP_TITLE;
if (process.env.REACT_APP_URL_API_SERVER) config.URL_API_SERVER = process.env.REACT_APP_URL_API_SERVER;
if (process.env.REACT_APP_NEED_LOGIN) config.USE_LOGIN = process.env.REACT_APP_NEED_LOGIN === 'true';

config.DEBUG = false;//MySystem.IsDevelopBuild();// process.env.REACT_APP_DEBUG === 'true';

// if(!config.DEBUG) console.log = function () {};
