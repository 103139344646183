import * as fbFirestore from 'firebase/firestore';
import { MyUtil } from '../../common/base/MyUtil';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { config } from '../../common/config/config';
import { lang } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { MySystem } from '../../common/util/MySystem';

export interface WUser {
   displayName: string | null;
   email: string | null;
   emailVisibility: 'private' | 'friends' | 'public';
   // phoneNumber: string,
   photoURL: string | null;
   // providerId: string,
   uid: string | null;
   // nickName: string | null;
   desc: string | null;
   authority: 'basic' | 'pro' | 'enterprise' | 'manager' | 'admin';
}

export interface WMapInfoData {
   // copyAllow?: boolean;
   // editorChoice?: number;
   // viewCount?: number;
   title?: string;
   desc?: string;
   owner?: string;
   ownerName?: string;
   ownerPhoto?: string;
   size?: string;
   category: string;
   map?: string;
   visibility?: 'private' | 'friends' | 'public';
   editor?: string[];
   updatedAt?: any;
   sort?: number;
}

export interface WDeco {
   [key: string]: { id: string; x: number; y: number; z: number; r: number; m?: any };
}

export interface WMapData {
   mapId: string;
   deco: WDeco;
   updatedAt?: any;
}

/**
 * 전체 관리자
 */
export class WRepository {
   // static users: { uid: string; data: any }[] = [];
   static mapCategory?: string;
   static mapList: { uid: string; data: any }[] = [];
   // static hotMapList: { uid: string; data: any }[] = [];

   static #user?: WUser;

   static get user() {
      return this.#user;
   }

   static map?: { uid: string; data: WMapInfoData };
   static mapData?: { uid: string; data: WMapData };

   static initialized = false;
   static async Init() {
      // 파이어베이스 유저면
      if (config.USE_LOGIN && MyFirebaseM.user) {
         // 로그인 정보 가져오기
         let ret = await this.GetUserInfo(MyFirebaseM.user.uid);
         this.#user = ret?.data as WUser;
         if (!this.user || !this.user.authority) {
            // 로그인 되어 있지 않으면 로그인 정보 다시 저장
            let userData: WUser = {
               displayName: MyFirebaseM.user.displayName,
               email: MyFirebaseM.user.email,
               emailVisibility: 'public',
               photoURL: MyFirebaseM.user.photoURL,
               uid: MyFirebaseM.user.uid,
               desc: '',
               authority: 'basic',
            };
            await this.SaveMyInfo(userData, true);
         }
      }
      this.initialized = true;
   }

   static async LoadMap(mapId: string) {
      this.map = await MyFirebaseM.DBFindOne(`maps`, mapId);
      if (this.map) {
         // 데이터 로딩
         this.mapData = await this.GetMapData(mapId);
         // console.log(this.mapData);
         // if (!this.mapData) {
         //    // 맵 데이터가 없으면 생성
         //    if (!this.map.data.deco) this.map.data.deco = {};
         //    let saveData = {
         //       mapId: this.map.uid,
         //       deco: { ...this.map.data.deco },
         //    };
         //    await MyFirebaseM.DBUpdate('mapData', this.map.uid, saveData);
         //    this.mapData = {
         //       uid: this.map.uid,
         //       data: saveData,
         //    };
         // }
      }
   }

   static async GetMapData(mapId: string) {
      return await MyFirebaseM.DBFindOne('mapData', mapId);
   }

   static async SaveMapData(mapId: string, dataSave: any) {
      await MyFirebaseM.DBUpdate('mapData', mapId, dataSave);
   }

   static async Login() {
      await MyFirebaseM.Login();
   }

   static async Logout() {
      await MyFirebaseM.Logout();
      this.#user = undefined;
      // this.mapList = [];
      MySystem.ForceUpdate();
      MsgBoxM.Open(lang.common.로그아웃되었습니다, lang.common.ok);
   }

   static async SaveMyInfo(saveData: WUser, force = false) {
      if (force || (await this.CheckLogin())) {
         await MyFirebaseM.DBUpdate('users', MyFirebaseM.user?.uid!, saveData);
         if (this.#user === undefined) (this.#user as any) = {};
         Object.assign(this.#user as any, saveData);
      }
   }

   static async GetUserInfo(uid: string) {
      return await MyFirebaseM.DBFindOne('users', uid);
   }

   /** 로그인 여부 체크 */
   static async CheckLogin() {
      if (!config.USE_LOGIN) return false;
      if (this.user) return true;
      let ret = await MsgBoxM.Open(lang.common.로그인이필요합니다, lang.common.로그인, lang.common.cancel);
      if (ret.btn === lang.common.로그인) {
         MyFirebaseM.Login();
      }
      return false;
   }

   static async DeleteMap(mapId: string) {
      if (await this.CheckLogin()) {
         // await MyFirebaseM.DBDeleteDocsWhere('mapData', fbFirestore.where('mapId', '==', mapId));
         await MyFirebaseM.DBDeleteDoc(`mapData`, mapId); //맵 부터 삭제하면 owner 권한이 사라지기 때문에 맵데이터 부터 삭제해야 한다.
         await MyFirebaseM.DBDeleteDoc('maps', mapId);
         this.mapList = this.mapList.filter((v) => v.uid !== mapId);
         // this.hotMapList = this.hotMapList.filter((v) => v.uid !== mapId);
      }
   }

   static async LoadMapList(category?: string, limit_ = 100) {
      await MyUtil.WaitUntil(() => this.initialized);
      category && (this.mapCategory = category);
      if (this.mapCategory === 'my') {
         if (this.user) {
            this.mapList = await MyFirebaseM.DBFindWhere(
               'maps',
               //WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
               fbFirestore.where('owner', '==', this.user?.uid),
               fbFirestore.orderBy('updatedAt', 'desc'),
               fbFirestore.limit(limit_),
            );
         } else {
            this.mapList = [];
         }
      } else if (this.mapCategory === 'all') {
         this.mapList = await MyFirebaseM.DBFindWhere(
            'maps',
            //WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
            fbFirestore.where('visibility', '==', 'public'),
            fbFirestore.orderBy('sort', 'desc'),
            fbFirestore.orderBy('updatedAt', 'desc'),
            fbFirestore.limit(limit_),
         );
      } else {
         this.mapList = await MyFirebaseM.DBFindWhere(
            'maps',
            //WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
            fbFirestore.where('visibility', '==', 'public'),
            fbFirestore.where('category', '==', this.mapCategory),
            fbFirestore.orderBy('sort', 'desc'),
            fbFirestore.orderBy('updatedAt', 'desc'),
            fbFirestore.limit(limit_),
         );
      }
      MySystem.ForceUpdate();
   }

   static async CreateMap(dataSave: WMapInfoData, deco: {}) {
      if (await this.CheckLogin()) {
         let [mapId] = await MyFirebaseM.DBAdd('maps', dataSave);
         await this.SaveMapData(mapId, {
            mapId: mapId,
            deco: deco,
         });
         return mapId;
      }
   }

   static async SaveMapInfo(mapId: string, dataSave: any, notice = true) {
      if (await this.CheckLogin()) {
         await MyFirebaseM.DBUpdate('maps', mapId, dataSave);
         if (notice) MsgBoxM.Open(lang.common.저장되었습니다, lang.common.ok);
      }
   }
}
