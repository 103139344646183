import { IMsgBox } from './MsgBoxReducer';
import { reduxStore } from '../../reducer/reduxStore';

/**
 * 메시지박스 리듀서
 * @author elee
 * @date 2021-03-29,
 */
const SET_MSGBOX = 'SET_MSGBOX2';

export namespace ActionModal {
  //메인 메뉴 슬라이드 열기 닫기
  export function setMsgBox(open: IMsgBox) {
    reduxStore.dispatch({ type: SET_MSGBOX, payload: open });
  }
}

const initialState: IMsgBox = {
  children: '',
  isOpen: false,
};

export function ModalReducer(state: IMsgBox = initialState, action: { type: any; payload: any }): IMsgBox {
  switch (action.type) {
    case SET_MSGBOX:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
}
